import { makeAutoObservable } from 'mobx';
import { Company } from '../models/Company';

export default class CompanyStore {
    selectedCompany = new Company();

    constructor() {
        makeAutoObservable(this);
    }

    rehydrate(data) {
        // Rehydrate the store with the saved data
        if (data) {
            Object.assign(this.selectedCompany, data.selectedCompany);
        }
    }

    toJSON() {
        // Return the store data to be saved
        return {
            selectedCompany: this.selectedCompany,
        };
    }

    setSelectedCompany(company) {
        this.selectedCompany = company;
    }

    getSelectedCompany() {
        return this.selectedCompany;
    }

    getSelectedCompanyId() {
        return this.selectedCompany.id;
    }

    setSelectedCompanyName(name) {
        this.selectedCompany.name = name;
    }

    getSelectedCompanyName() {
        return this.selectedCompany.name;
    }

    clearStore() {
        this.selectedCompany = new Company();
    }
}
