import React, { useState, useContext, useEffect } from "react";
import classes from "../styling/UserInfoDisplay.module.css";
import { MobXProviderContext } from "mobx-react";
import { storage } from "../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
// A component to display the info stored in each user from the users collection in firestore
// It specifically displays the full name, email, and phone number of each user
const UserInfoDisplay = ({ key, user }) => {
  const [imageURL, setImageURL] = useState("");
  const { domainStore } = useContext(MobXProviderContext);

  useEffect(() => {
    const usr = user.user;

    const imageRef = ref(storage, "profilePic/" + usr.id);

    getDownloadURL(imageRef)
      .then((url) => {
        setImageURL(url);
      })
      .catch((error) => {
        const defaultImage = ref(storage, "profilePic/blank-profile.png");
        getDownloadURL(defaultImage)
          .then((url) => {
            domainStore.userStore.setProfilePicture(url);
            setImageURL(url);
          })
          .catch((error) => {
            console.error("Error fetching image:", error);
          });
      });
  }, [user]);

  return (
    <li key={key} className={classes.userDisplayContainer}>
      {imageURL && (
        <img alt="profile pic" src={imageURL} className={classes.profilePic} />
      )}
      <div className={classes.infoDiv}>
        <h1 className={classes.userInfoDisplayTitle}>Name:</h1>
        <p
          className={classes.userInfoDisplay}
        >{`${user.user.firstName} ${user.user.lastName}`}</p>
      </div>
      <div className={classes.infoDiv}>
        <h1 className={classes.userInfoDisplayTitle}>Email:</h1>
        <p className={classes.userInfoDisplay}>{user.email}</p>
      </div>
      <div className={classes.infoDiv}>
        <h1 className={classes.userInfoDisplayTitle}>Phone Number:</h1>
        <p className={classes.userInfoDisplay}>{user.user.contactPhone}</p>
      </div>
    </li>
  );
};

export default UserInfoDisplay;
