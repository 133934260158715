import { makeAutoObservable } from 'mobx';

export class User {
  id = '';
  firstName = '';
  lastName = '';
  email = '';
  contactPhone = '';
  profilePicture = '';
  companies = [];
  defaultCompany = '';

  constructor(data = {}) {
    this.id = data.id || '';
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.email = data.email || '';
    this.contactPhone = data.contactPhone || '';
    this.profilePicture = data.profilePicture || '';
    this.companies = data.companies || [];
    this.defaultCompany = data.defaultCompany || '';
    makeAutoObservable(this);
  }
}
