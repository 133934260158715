import React, { useState, useEffect, useContext } from 'react';
import { auth } from '../../firebase';
import { createNewAnnouncement, getCompanyAnnouncements } from '../../queries/companyQueries';
import { MobXProviderContext } from 'mobx-react';
import "../styling/InboxScreen.modules.css";

const InboxScreen = () => {
  const [createAnnouncement, setCreateAnnouncement] = useState(false);
  const [announcement, setAnnouncement] = useState('');
  const [announcements, setAnnouncements] = useState([]);
  const [error, setError] = useState('');
  const { domainStore } = useContext(MobXProviderContext);

  const handleSave = async () => {
    const companyID = domainStore.companyStore.getSelectedCompany().id;
      if (!companyID) {
        setError('Error: Company ID is undefined. Please select a company.');
        return;
      }
    const announcementToPost = { 
      companyID: companyID, 
      userID: auth.currentUser?.uid, 
      message: announcement,
      date: new Date().toISOString(),
      userName: auth.currentUser?.email
    };
    await createNewAnnouncement(announcementToPost);
    setCreateAnnouncement(false);
    fetchData();
  };

  const fetchData = async () => {
    const companyID = domainStore.companyStore.getSelectedCompanyId();
    if (!companyID) {
      setError('Error: Company ID is undefined. Please select a company.');
      return;
    }
    const fetchedAnnouncements = await getCompanyAnnouncements(companyID);
    fetchedAnnouncements.sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date
    setAnnouncements(fetchedAnnouncements);
    setAnnouncement('');
  };

  useEffect(() => {
    fetchData();
  });

  const renderAnnouncement = (item) => {
    const date = new Date(item.date);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return (
      <div className="announcementContainer">
        <div className="header">
          <span className="userName">{item.userName}</span>
          <span className="dateTime">{`${formattedDate} ${formattedTime}`}</span>
        </div>
        <div className="announcementText">{item.message}</div>
      </div>
    );
  };


  if (error) {
    return <div className="error">{error}</div>;
  }
  else {
  return (
    <div className="container">
      <div className="announcementsList">
        {announcements.map((announcement, index) => (
          <div key={index}>
            {renderAnnouncement(announcement)}
          </div>
        ))}
      </div>
      <div>
        {createAnnouncement && (
          <div className="inputContainer">
            <input
              className="input"
              placeholder="Create Announcement"
              value={announcement}
              onChange={(e) => setAnnouncement(e.target.value)}
            />
            <button onClick={handleSave} className="button">
              Create Announcement
            </button>
          </div>
        )}
      </div>
      <div className="buttonContainer">
        <button onClick={() => { setCreateAnnouncement(!createAnnouncement); }} className="button">
          Create Announcement
        </button>
      </div>
    </div>
  );
}
};

export default InboxScreen;
