import React from 'react';
import CourseList from '../../components/code/CourseList';
import { useNavigate } from 'react-router-dom';

const CourseScreen = () => {
    const navigate = useNavigate();

    const handleCreateCourseClick = () => {
        navigate('/coursecreator');
    };

    return (
        <div style={{ padding: '16px' }}>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>Course Management</h1>

            <div style={{ marginBottom: '16px' }}>
                <button
                    onClick={handleCreateCourseClick}
                    style={{
                        padding: '8px 16px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                    }}
                >
                    Create Course
                </button>
            </div>

            <CourseList />
        </div>
    );
};

export default CourseScreen;