import React, { useState, useContext, useEffect } from "react";
import { MobXProviderContext } from "mobx-react";
import { storage } from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import classes from "../../styling/chat_screen/ChatUserCard.module.css";

const ChatUserCard = ({ userInfo, onClick }) => {
  const onClickHandle = async (e) => {
    e.preventDefault();
    onClick(userInfo);
  };
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const usr = userInfo.user;

    const imageRef = ref(storage, "profilePic/" + usr.id);

    getDownloadURL(imageRef)
      .then((url) => {
        setImageURL(url);
      })
      .catch((error) => {
        const defaultImage = ref(storage, "profilePic/blank-profile.png");
        getDownloadURL(defaultImage)
          .then((url) => {
            setImageURL(url);
          })
          .catch((error) => {
            console.error("Error fetching image:", error);
          });
      });
  }, [userInfo]);

  return (
    <button
      key={userInfo.key}
      onClick={onClickHandle}
      className={classes.outerCard}
    >
      <div className={classes.imageContainer}>
        {imageURL && (
          <img
            alt="profile pic"
            src={imageURL}
            className={classes.profilePic}
          />
        )}
      </div>
      <div className={classes.middleBox}>
        <h2 className={classes.innerText}>
          {`${userInfo.user.firstName} ${userInfo.user.lastName}   Role: ${userInfo.role}`}
        </h2>
        {userInfo.lastMessage !== "" && userInfo.lastMessage ? (
          <p className={classes.lastMessage}>
            {userInfo.lastMessage.length < 50
              ? `"${userInfo.lastMessage}"`
              : `"${userInfo.lastMessage.slice(0, 50)}..."`}
          </p>
        ) : (
          <></>
        )}
      </div>
      {userInfo.unreadMessages > 0 ? (
        <div className={classes.unreadOuterContainer}>
          <p
            className={classes.unreadInnerText}
          >{`${userInfo.unreadMessages}`}</p>
        </div>
      ) : (
        <></>
      )}
    </button>
  );
};

export default ChatUserCard;
