import React from 'react';
import NavBar from '../components/code/NavBar';

const MainWrapper = ({ children }) => {
  return (
    <>
      <NavBar />
      <main>{children}</main>
    </>
  );
};

export default MainWrapper;
