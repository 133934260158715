import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import LoginScreen from "../screens/code/LoginScreen";
import SignUpScreen from "../screens/code/SignUpScreen";
import UserList from "../screens/code/UserList";
import MainWrapper from "../wrapper/MainWrapper";
import Profile from "../screens/code/Profile";
import ChatScreen from "../screens/code/ChatScreen";
import CompaniesScreen from "../screens/code/CompaniesScreen";
import { auth } from "../firebase";
import { MobXProviderContext } from "mobx-react";
import CourseScreen from "../screens/code/CourseScreen";
import CourseDetail from "../screens/code/CourseDetail";
import ChapterPage from "../screens/code/ChapterPage";
import CourseCreatorScreen from "../screens/code/CourseCreatorScreen";
import InboxScreen from "../screens/code/InboxScreen";
import VerifyEmail from "../components/code/VerifyEmail";

const ParentRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/signup" element={<SignUpScreen />} />
      <Route path="/" element={<PrivateRoute element={<MainWrapper><UserList /></MainWrapper>} />} />
      <Route path="/profile" element={<PrivateRoute element={<MainWrapper><Profile /></MainWrapper>} />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/settings" element={<PrivateRoute element={<MainWrapper><h1>Settings</h1></MainWrapper>} />} />
      <Route path="/chat" element={<PrivateRoute element={<MainWrapper><ChatScreen /></MainWrapper>} />} />
      <Route path="/companies" element={<PrivateRoute element={<MainWrapper><CompaniesScreen /></MainWrapper>} />} />
      <Route path="/courses" element={<PrivateRoute element={<MainWrapper><CourseScreen /></MainWrapper>} />} />
      <Route path="/courses/:courseId" element={<PrivateRoute element={<MainWrapper><CourseDetail /></MainWrapper>} />} />
      <Route path="/courses/:courseId/chapters/:chapterId" element={<PrivateRoute element={<MainWrapper><ChapterPage /></MainWrapper>} />} />
      <Route path="/coursecreator" element={<PrivateRoute element={<MainWrapper><CourseCreatorScreen /></MainWrapper>} />} />
      <Route path="/announcements" element={<PrivateRoute element={<MainWrapper><InboxScreen /></MainWrapper>} />} />
    </Routes>
  );
};

const isTokenValid = () => {
  const token = localStorage.getItem("token");
  const expiration = localStorage.getItem("token_expiration");

  if (!token || !expiration) {
    return false;
  }

  const currentTime = new Date().getTime();
  return currentTime < parseInt(expiration, 10);
};

const handleInvalidToken = (domainStore) => {
  auth.signOut();
  domainStore.clearStores();
  localStorage.removeItem("token");
  localStorage.removeItem("token_expiration");
  localStorage.removeItem("domainStore");
};

const PrivateRoute = ({ element, ...rest }) => {
  const { domainStore } = useContext(MobXProviderContext);

  if (!isTokenValid()) {
    handleInvalidToken(domainStore);
    return <Navigate to="/login" replace />;
  }
  return element;
};

export default ParentRoutes;