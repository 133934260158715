import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCourses } from '../../services/CourseService';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Box,
  Snackbar,
  Alert,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({ field: '', order: 'asc' });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [editingCourseId, setEditingCourseId] = useState(null);
  const [editingTag, setEditingTag] = useState('');
  const [editingTagColor, setEditingTagColor] = useState('');
  const pastelColors = ['#FFB3BA', '#BAFFC9', '#BAE1FF', '#FFFFBA', '#FFDFBA', '#E0BBE4'];

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const coursesData = await getCourses(searchTerm, sort, selectedTags);
        setCourses(coursesData);
        
        const tags = [...new Set(coursesData.flatMap(course => 
          course.tags.map(tag => typeof tag === 'object' ? tag.text : tag)
        ))];
        setAllTags(tags);
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        setLoading(false);
      }
    };

    const debounce = setTimeout(() => {
      fetchCourses();
    }, 300);

    return () => clearTimeout(debounce);
  }, [searchTerm, sort, selectedTags]);

  useEffect(() => {
    const message = sessionStorage.getItem('courseAddedMessage');
    if (message) {
      setShowPopup(true);
      sessionStorage.removeItem('courseAddedMessage');
    }
  }, []);

  const handleSortChange = (e) => {
    const [field, order] = e.target.value.split(':');
    setSort({ field, order });
  };

  const handleCourseClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTagSelect = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  // Add these missing functions
  const handleTagClick = (e, courseId, tag) => {
    e.stopPropagation();
    setEditingCourseId(courseId);
    setEditingTag(typeof tag === 'object' ? tag.text : tag);
    setEditingTagColor(typeof tag === 'object' ? tag.color : pastelColors[0]);
  };

  const handleColorChange = (color) => {
    setEditingTagColor(color);
  };

  const handleTagChange = (e, courseId, oldTag) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      e.preventDefault();
      const newTag = e.target.value.trim();
      if (newTag && (newTag !== (typeof oldTag === 'object' ? oldTag.text : oldTag) || editingTagColor !== (typeof oldTag === 'object' ? oldTag.color : pastelColors[0]))) {
        const updatedCourses = courses.map(course => {
          if (course.id === courseId) {
            const newTags = course.tags.map(t => 
              (typeof t === 'object' ? t.text : t) === (typeof oldTag === 'object' ? oldTag.text : oldTag) 
                ? { text: newTag, color: editingTagColor }
                : t
            );
            return { ...course, tags: newTags };
          }
          return course;
        });
        setCourses(updatedCourses);
        // You might want to add a function to update the tag in the database here
      }
      setEditingCourseId(null);
      setEditingTag('');
      setEditingTagColor('');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Available Courses
      </Typography>

      <Snackbar
        open={showPopup}
        autoHideDuration={6000}
        onClose={() => setShowPopup(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setShowPopup(false)} severity="success" sx={{ width: '100%' }}>
          Course added successfully!
        </Alert>
      </Snackbar>

      <Box sx={{ mb: 4, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          label="Search courses"
          name="search"
          value={searchTerm}
          onChange={handleSearchChange}
          variant="outlined"
          size="small"
          sx={{ flexGrow: 1, minWidth: '200px' }}
        />
        <FormControl variant="outlined" size="small" sx={{ minWidth: '200px' }}>
          <InputLabel>Sort by</InputLabel>
          <Select
            value={`${sort.field}:${sort.order}`}
            onChange={handleSortChange}
            label="Sort by"
          >
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="name:asc">Name (A-Z)</MenuItem>
            <MenuItem value="name:desc">Name (Z-A)</MenuItem>
            <MenuItem value="time:asc">Time (Low to High)</MenuItem>
            <MenuItem value="time:desc">Time (High to Low)</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" size="small" sx={{ minWidth: '200px' }}>
          <InputLabel id="filter-by-tags-label">Filter by Tags</InputLabel>
          <Select
            labelId="filter-by-tags-label"
            id="filter-by-tags"
            multiple
            value={selectedTags}
            onChange={handleTagSelect}
            input={<OutlinedInput label="Filter by Tags" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {allTags.map((tag) => (
              <MenuItem key={tag} value={tag}>
                <Checkbox checked={selectedTags.indexOf(tag) > -1} />
                <ListItemText primary={tag} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Grid container spacing={4}>
          {courses.map((course) => (
            <Grid item key={course.id} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                  borderRadius: 2,
                  boxShadow: 3,
                  border: 1,
                  borderColor: 'divider',
                  '&:hover': { 
                    boxShadow: 5,
                  },
                }}
                onClick={() => handleCourseClick(course.id)}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={course.banner}
                  alt={`${course.name} banner`}
                  sx={{
                    objectFit: 'cover',
                    borderBottom: 1,
                    borderColor: 'divider',
                  }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <SchoolIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography gutterBottom variant="h5" component="h2">
                      {course.name}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {course.description}
                  </Typography>
                  <Box sx={{ mt: 2, mb: 1 }}>
                    {Array.isArray(course.tags) &&
                      course.tags.map((tag, index) => {
                        const tagText = typeof tag === 'object' ? tag.text : tag;
                        const tagColor = typeof tag === 'object' ? tag.color : pastelColors[index % pastelColors.length];
                        
                        return editingCourseId === course.id && editingTag === tagText ? (
                          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mr: 0.5, mb: 0.5 }}>
                            <TextField
                              size="small"
                              value={editingTag}
                              onChange={(e) => setEditingTag(e.target.value)}
                              onKeyDown={(e) => handleTagChange(e, course.id, tag)}
                              onBlur={(e) => handleTagChange(e, course.id, tag)}
                              autoFocus
                              sx={{ 
                                width: 'auto',
                                minWidth: '60px',
                                '& .MuiInputBase-input': {
                                  padding: '2px 4px',
                                  fontSize: '0.8125rem',
                                }
                              }}
                            />
                            <Box sx={{ display: 'flex', ml: 1 }}>
                              {pastelColors.map((color) => (
                                <Box
                                  key={color}
                                  onClick={() => handleColorChange(color)}
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    backgroundColor: color,
                                    cursor: 'pointer',
                                    border: editingTagColor === color ? '2px solid black' : 'none',
                                    mr: 0.5,
                                  }}
                                />
                              ))}
                            </Box>
                          </Box>
                        ) : (
                          <Chip
                            key={index}
                            label={tagText}
                            size="small"
                            onClick={(e) => handleTagClick(e, course.id, { text: tagText, color: tagColor })}
                            sx={{ 
                              mr: 0.5, 
                              mb: 0.5, 
                              backgroundColor: tagColor,
                              color: 'text.primary',
                              cursor: 'pointer',
                              border: '1px solid rgba(0, 0, 0, 0.23)',
                            }}
                          />
                        );
                      })
                    }
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Time:</strong> {course.time}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Author:</strong> {course.author}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default CourseList;