import { User } from "./User";

export class CompanyUser {
  constructor(data) {
    if (data != null) {
      this.id = data.id;
      this.email = data.email;
      this.companyID = data.companyID;
      this.role = data.role;
      this.isSuspended = data.isSuspended;
      this.user = data.user ? new User(data.user) : new User();
    }
  }
}
