import React, { useState, useContext } from 'react';
import UploadAndDisplayImage from '../../components/code/UploadAndDisplayImage';
import { MobXProviderContext } from 'mobx-react';
import { updateUserProfile, checkEmailUniqueness } from '../../queries/userQueries';
import { getAuth, verifyBeforeUpdateEmail } from 'firebase/auth';
import '../styling/ProfileScreen.module.css';

const Profile = () => {
  const { domainStore } = useContext(MobXProviderContext);
  const [firstName, setFirstName] = useState(domainStore.userStore.currentUser.firstName);
  const [lastName, setLastName] = useState(domainStore.userStore.currentUser.lastName);
  const [email, setEmail] = useState(domainStore.userStore.currentUser.email);
  const [contactPhone, setContactPhone] = useState(domainStore.userStore.currentUser.contactPhone);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isEmailVerificationSent, setIsEmailVerificationSent] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const nameRegex = /^[a-zA-Z]+$/; // Only letters allowed

    if (!firstName.trim()) newErrors.firstName = "First name is required";
    else if (!nameRegex.test(firstName)) newErrors.firstName = "First name should only contain letters";

    if (!lastName.trim()) newErrors.lastName = "Last name is required";
    else if (!nameRegex.test(lastName)) newErrors.lastName = "Last name should only contain letters";

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) newErrors.email = "Invalid email format";
    const phoneRegex = /^(\+\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
    if (!phoneRegex.test(contactPhone)) newErrors.contactPhone = "Invalid phone number format";
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateUser = async () => {
    if (!validateForm()) return;

    try {
      const currentEmail = domainStore.userStore.currentUser.email;
      if (email !== currentEmail) {
        const isUnique = await checkEmailUniqueness(email);
        if (!isUnique) {
          setErrors(prev => ({ ...prev, email: "This email is already in use" }));
          return;
        }

        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          try {
            await verifyBeforeUpdateEmail(user, email, {
              url: `${window.location.origin}/verify-email-change?newEmail=${encodeURIComponent(email)}`,
              handleCodeInApp: true
            });
            setIsEmailVerificationSent(true);
            setErrors(prev => ({ ...prev, email: "Verification email sent. Please check your inbox and verify before saving." }));
          } catch (verificationError) {
            console.error('Error sending verification email:', verificationError);
            setErrors(prev => ({ ...prev, email: `Error sending verification email: ${verificationError.message}` }));
          }
          return;
        } else {
          throw new Error("User not authenticated");
        }
      }

      const user = {
        id: domainStore.userStore.currentUser.id,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        contactPhone: contactPhone.trim(),
      };
      await updateUserProfile(user);
      domainStore.userStore.setCurrentUserProfile(user);
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      console.error('Issue saving user profile: ', error);
      setErrors(prev => ({ ...prev, general: error.message }));
    }
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
    if (!/^[a-zA-Z]*$/.test(value)) {
      setErrors(prev => ({ ...prev, firstName: "First name should only contain letters" }));
    } else {
      setErrors(prev => ({ ...prev, firstName: null }));
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
    if (!/^[a-zA-Z]*$/.test(value)) {
      setErrors(prev => ({ ...prev, lastName: "Last name should only contain letters" }));
    } else {
      setErrors(prev => ({ ...prev, lastName: null }));
    }
  };

  return (
    <div className="container">
      <h1 className="title">Profile</h1>
      <div className="input-container">
        <input
          className={`input ${errors.firstName ? 'error' : ''}`}
          type="text"
          value={firstName}
          onChange={handleFirstNameChange}
          placeholder="First Name"
        />
        {errors.firstName && <div className="error-message">{errors.firstName}</div>}
        
        <input
          className={`input ${errors.lastName ? 'error' : ''}`}
          type="text"
          value={lastName}
          onChange={handleLastNameChange}
          placeholder="Last Name"
        />
        {errors.lastName && <div className="error-message">{errors.lastName}</div>}
        
        <input
          className={`input ${errors.email ? 'error' : ''}`}
          type="email"
          value={email}
          disabled={true}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        {errors.email && <div className="error-message">{errors.email}</div>}
        
        <input
          className={`input ${errors.contactPhone ? 'error' : ''}`}
          type="tel"
          value={contactPhone}
          onChange={(e) => setContactPhone(e.target.value)}
          placeholder="Contact Phone"
        />
        {errors.contactPhone && <div className="error-message">{errors.contactPhone}</div>}
        
        <button className="button" onClick={updateUser}>
          Save
        </button>
      </div>
      {showSuccessMessage && <div className="success-message show">Profile updated successfully!</div>}
      {isEmailVerificationSent && (
        <div className="info-message">
          A verification email has been sent to your new email address. 
          Please verify it before saving your profile changes.
        </div>
      )}
      <UploadAndDisplayImage />
      <h2 className="company-name">Selected Company: {domainStore.companyStore.selectedCompany.name}</h2>
    </div>
  );
};

export default Profile;