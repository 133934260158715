import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { addCourse } from '../../services/CourseService';
import RichTextEditor from './RichTextEditor';
import { auth } from '../../firebase';
import { getUserByID } from '../../queries/userQueries';
import BannerUploadAndDisplay from './BannerUploadAndDisplay';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Grid,
  Paper,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const isValidYouTubeUrl = (url) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegex.test(url);
};

const validateQuizQuestions = (questions) => {
    let errors = [];
    questions.forEach((question, index) => {
        if (!question.question.trim()) {
            errors.push(`Question ${index + 1}: Question text is required`);
        }
        if (question.type === 'MCQ') {
            const nonEmptyOptions = question.options.filter(option => option.trim());
            if (nonEmptyOptions.length < 2) {
                errors.push(`Question ${index + 1}: At least two non-empty options are required`);
            }
            if (question.options.some(option => !option.trim())) {
                errors.push(`Question ${index + 1}: All options must be non-empty`);
            }
            if (!question.correctAnswer.trim()) {
                errors.push(`Question ${index + 1}: Correct answer is required`);
            } else if (!nonEmptyOptions.includes(question.correctAnswer)) {
                errors.push(`Question ${index + 1}: Correct answer must be one of the non-empty options`);
            }
        }
        if (question.type === 'true-false' && !['true', 'false'].includes(question.correctAnswer)) {
            errors.push(`Question ${index + 1}: Correct answer must be either true or false`);
        }
    });
    return errors;
};

const CourseCreator = () => {
    const navigate = useNavigate();
    const [course, setCourse] = useState({
        name: '',
        tags: [],
        time: {
            hours: '00',
            minutes: '00',
            seconds: '00'
        },
        banner: '',
        description: '',
        author: '',
        modules: []
    });
    
    const [currentModule, setCurrentModule] = useState({
        title: '',
        chapters: []
    });
    const [chapterTitle, setChapterTitle] = useState('');
    const [chapterContent, setChapterContent] = useState('');
    const [chapterType, setChapterType] = useState('reading');
    const [quizQuestions, setQuizQuestions] = useState([
        { type: 'MCQ', question: '', options: ['', ''], correctAnswer: '' }
    ]);
    const [message, setMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editorKey, setEditorKey] = useState(0);
    const [editingTagId, setEditingTagId] = useState(null);
    const [editingTagText, setEditingTagText] = useState('');
    const [editingTagColor, setEditingTagColor] = useState('');
    const [selectedBanner, setSelectedBanner] = useState(null);
    const pastelColors = ['#FFB3BA', '#BAFFC9', '#BAE1FF', '#FFFFBA', '#FFDFBA', '#E0BBE4'];

    useEffect(() => {
        const fetchUserData = async () => {
            if (auth.currentUser) {
                const userData = await getUserByID(auth.currentUser.uid);
                setCourse(prevCourse => ({
                    ...prevCourse,
                    author: `${userData.firstName} ${userData.lastName}`
                }));
            }
            };
            fetchUserData();
        }, []);

    const handleTimeChange = (field, value) => {
        setCourse(prevCourse => ({
            ...prevCourse,
            time: {
                ...prevCourse.time,
                [field]: value.padStart(2, '0')
            }
        }));
    };

    const handleAddChapter = () => {
        let chapterErrors = {};
        if (!chapterTitle.trim()) chapterErrors.title = "Chapter title is required";
        if (chapterType === 'quiz') {
            const quizErrors = validateQuizQuestions(quizQuestions);
            if (quizErrors.length > 0) {
                chapterErrors.questions = quizErrors;
            }
        } else if (chapterType === 'reading') {
            if (!chapterContent.trim() || chapterContent === '<p></p>') {
                chapterErrors.content = "Chapter content is required";
            }
        } else if (chapterType === 'video') {
            if (!chapterContent.trim()) {
                chapterErrors.content = "YouTube URL is required";
            } else if (!isValidYouTubeUrl(chapterContent)) {
                chapterErrors.content = "Please enter a valid YouTube URL";
            }
        }

        if (Object.keys(chapterErrors).length === 0) {
            let newChapter = { title: chapterTitle, type: chapterType };
            if (chapterType === 'quiz') {
                newChapter.questions = quizQuestions;
            } else {
                newChapter.content = chapterContent;
            }
            setCurrentModule({
                ...currentModule,
                chapters: [...currentModule.chapters, newChapter]
            });
            setChapterTitle('');
            setChapterContent('');
            setChapterType('reading');
            setQuizQuestions([{ type: 'MCQ', question: '', options: ['', ''], correctAnswer: '' }]);
            setErrors({});
            setEditorKey(prevKey => prevKey + 1);
        } else {
            setErrors(chapterErrors);
        }
    };

    const handleAddModule = () => {
        if (currentModule.title.trim() && currentModule.chapters.length > 0) {
            setCourse({
                ...course,
                modules: [...course.modules, currentModule]
            });
            setCurrentModule({
                title: '',
                chapters: []
            });
        } else {
            setErrors({
                module: "Module title and at least one chapter are required"
            });
        }
    };

    const handleAddCourse = () => {
        setIsSubmitting(true);
        if (validateCourse()) {
            setShowConfirmation(true);
        }
        setIsSubmitting(false);
    };

    const confirmAddCourse = async () => {
        setIsSubmitting(true);
        if (validateCourse()) {
            try {
                let bannerUrl = '';
                if (selectedBanner) {
                    bannerUrl = await uploadBanner(selectedBanner);
                }

                const tagsArray = course.tags ? course.tags.map(tag => tag.text) : [];
                const formattedTime = `${course.time.hours}:${course.time.minutes}:${course.time.seconds}`;
                await addCourse({ 
                    ...course, 
                    tags: tagsArray, 
                    time: formattedTime,
                    banner: bannerUrl
                });
                setCourse({
                    name: '',
                    tags: [],
                    time: { hours: '00', minutes: '00', seconds: '00' },
                    banner: '',
                    description: '',
                    author: course.author,
                    modules: []
                });
                setSelectedBanner(null);
                setShowConfirmation(false);
                sessionStorage.setItem('courseAddedMessage', 'Course added successfully');
                navigate('/courses');
            } catch (error) {
                setMessage('Error adding course: ' + error.message);
                console.error('Error adding course:', error);
            }
        }
        setIsSubmitting(false);
        setShowConfirmation(false);
    };

    const handleQuizQuestionChange = (index, field, value) => {
        const newQuizQuestions = [...quizQuestions];
        if (field === 'type') {
            newQuizQuestions[index] = {
                type: value,
                question: '',
                options: value === 'MCQ' ? ['', ''] : [],
                correctAnswer: value === 'true-false' ? 'true' : ''
            };
        } else if (field === 'question') {
            newQuizQuestions[index].question = value;
        } else if (field === 'option') {
            newQuizQuestions[index].options[value.index] = value.text;
            // Reset correct answer if the edited option was the correct answer
            if (newQuizQuestions[index].correctAnswer === newQuizQuestions[index].options[value.index]) {
                newQuizQuestions[index].correctAnswer = '';
            }
        } else if (field === 'correctAnswer') {
            newQuizQuestions[index].correctAnswer = value;
        } else if (field === 'addOption') {
            newQuizQuestions[index].options.push('');
        }
        setQuizQuestions(newQuizQuestions);
    };

    const handleAddQuizQuestion = () => {
        setQuizQuestions([...quizQuestions, { type: 'MCQ', question: '', options: ['', ''], correctAnswer: '' }]);
    };
    
    const handleRemoveQuizQuestion = (index) => {
        const newQuizQuestions = [...quizQuestions];
        newQuizQuestions.splice(index, 1);
        setQuizQuestions(newQuizQuestions);
    };

    const handleChapterTypeChange = (e) => {
        setChapterType(e.target.value);
        setChapterContent('');
        setErrors(prevErrors => ({
            ...prevErrors,
            title: undefined,
            content: undefined,
            questions: undefined,
            modules: undefined
        }));
        if (e.target.value === 'quiz') {
            setQuizQuestions([{ type: 'MCQ', question: '', options: ['', ''], correctAnswer: '' }]);
        }
    };

    const validateCourse = () => {
        let tempErrors = {};

        if (!course.name.trim()) tempErrors.name = "Course name is required";
        if (!course.description.trim()) tempErrors.description = "Course description is required";
        
        const totalSeconds = parseInt(course.time.hours) * 3600 + parseInt(course.time.minutes) * 60 + parseInt(course.time.seconds);
        if (totalSeconds === 0) tempErrors.time = "Course duration must be greater than 0";

        if (course.modules.length === 0) tempErrors.modules = "At least one module is required";

        course.modules.forEach((module, moduleIndex) => {
            if (!module.title.trim()) {
                tempErrors[`module${moduleIndex}`] = `Module ${moduleIndex + 1} title is required`;
            }
            if (module.chapters.length === 0) {
                tempErrors[`module${moduleIndex}Chapters`] = `Module ${moduleIndex + 1} must have at least one chapter`;
            }
            module.chapters.forEach((chapter, chapterIndex) => {
                if (!chapter.title.trim()) {
                    tempErrors[`module${moduleIndex}Chapter${chapterIndex}`] = `Chapter ${chapterIndex + 1} in Module ${moduleIndex + 1} title is required`;
                }
                if (chapter.type === 'quiz') {
                    const quizErrors = validateQuizQuestions(chapter.questions);
                    if (quizErrors.length > 0) {
                        tempErrors[`module${moduleIndex}Chapter${chapterIndex}Questions`] = quizErrors;
                    }
                } else if (chapter.type === 'reading') {
                    if (!chapter.content.trim() || chapter.content === '<p></p>') {
                        tempErrors[`module${moduleIndex}Chapter${chapterIndex}Content`] = `Chapter ${chapterIndex + 1} in Module ${moduleIndex + 1} content is required`;
                    }
                } else if (chapter.type === 'video') {
                    if (!chapter.content.trim() || !isValidYouTubeUrl(chapter.content)) {
                        tempErrors[`module${moduleIndex}Chapter${chapterIndex}Content`] = `Chapter ${chapterIndex + 1} in Module ${moduleIndex + 1} must have a valid YouTube URL`;
                    }
                }
            });
        });

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleTagClick = (tag, index) => {
        setEditingTagId(index);
        setEditingTagText(tag.text);
        setEditingTagColor(tag.color);
    };

    const handleColorChange = (color) => {
        setEditingTagColor(color);
    };

    const handleTagChange = (index) => {
        const newTagText = editingTagText.trim();
        if (newTagText && (newTagText !== course.tags[index].text || editingTagColor !== course.tags[index].color)) {
            const updatedTags = course.tags.map((tag, i) => 
                i === index ? { text: newTagText, color: editingTagColor } : tag
            );
            setCourse(prevCourse => ({
                ...prevCourse,
                tags: updatedTags
            }));
        }
        setEditingTagId(null);
        setEditingTagText('');
        setEditingTagColor('');
    };

    const handleAddTag = () => {
        const newTagText = editingTagText.trim();
        if (newTagText && !course.tags.some(tag => tag.text.toLowerCase() === newTagText.toLowerCase())) {
            const newColor = editingTagColor || pastelColors.find(color => 
                !course.tags.some(tag => tag.color === color)
            ) || pastelColors[0];
            
            setCourse(prevCourse => ({
                ...prevCourse,
                tags: [...prevCourse.tags, { text: newTagText, color: newColor }]
            }));
            setEditingTagText('');
            setEditingTagColor('');
        }
    };

    const uploadBanner = async (file) => {
        const storage = getStorage();
        const storageRef = ref(storage, `course_banners/${Date.now()}_${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        return await getDownloadURL(snapshot.ref);
    };

    return (
        <Box component={Paper} elevation={3} p={3} sx={{ maxWidth: 800, margin: 'auto', mt: 4, mb: 4 }}>
            <Typography variant="h4" gutterBottom>
                Create a New Course
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Course Name"
                        value={course.name}
                        onChange={(e) => setCourse({ ...course, name: e.target.value })}
                        error={!!errors.name}
                        helperText={errors.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Tags:</Typography>
                    <Box display="flex" flexWrap="wrap" alignItems="center">
                        {course.tags.map((tag, index) => (
                            editingTagId === index ? (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mr: 0.5, mb: 0.5 }}>
                                    <TextField
                                        size="small"
                                        value={editingTagText}
                                        onChange={(e) => setEditingTagText(e.target.value)}
                                        autoFocus
                                        sx={{ 
                                            width: 'auto',
                                            minWidth: '60px',
                                            '& .MuiInputBase-input': {
                                                padding: '2px 4px',
                                                fontSize: '0.8125rem',
                                            }
                                        }}
                                    />
                                    <Box sx={{ display: 'flex', ml: 1 }}>
                                        {pastelColors.map((color) => (
                                            <Box
                                                key={color}
                                                onClick={() => handleColorChange(color)}
                                                sx={{
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: color,
                                                    cursor: 'pointer',
                                                    border: editingTagColor === color ? '2px solid black' : 'none',
                                                    mr: 0.5,
                                                }}
                                            />
                                        ))}
                                    </Box>
                                    <Button 
                                        variant="contained"
                                        size="small" 
                                        onClick={() => handleTagChange(index)}
                                        sx={{ ml: 1 }}
                                    >
                                        Save
                                    </Button>
                                    <Button 
                                        variant="outlined"
                                        size="small" 
                                        onClick={() => {
                                            setEditingTagId(null);
                                            setEditingTagText('');
                                            setEditingTagColor('');
                                        }}
                                        sx={{ ml: 1 }}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            ) : (
                                <Chip
                                    key={index}
                                    label={tag.text}
                                    size="small"
                                    onClick={() => handleTagClick(tag, index)}
                                    sx={{ 
                                        mr: 0.5, 
                                        mb: 0.5, 
                                        backgroundColor: tag.color,
                                        color: 'text.primary',
                                        cursor: 'pointer',
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                    }}
                                />
                            )
                        ))}
                        {editingTagId === null && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5, mb: 0.5 }}>
                                <TextField
                                    size="small"
                                    value={editingTagText}
                                    onChange={(e) => setEditingTagText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleAddTag();
                                        }
                                    }}
                                    placeholder="New Tag"
                                    sx={{ 
                                        width: 'auto',
                                        minWidth: '100px',
                                        '& .MuiInputBase-input': {
                                            padding: '2px 4px',
                                            fontSize: '0.8125rem',
                                        }
                                    }}
                                />
                                <Box sx={{ display: 'flex', ml: 1 }}>
                                    {pastelColors.map((color) => (
                                        <Box
                                            key={color}
                                            onClick={() => handleColorChange(color)}
                                            sx={{
                                                width: 20,
                                                height: 20,
                                                backgroundColor: color,
                                                cursor: 'pointer',
                                                border: editingTagColor === color ? '2px solid black' : 'none',
                                                mr: 0.5,
                                            }}
                                        />
                                    ))}
                                </Box>
                                <Button 
                                    variant="contained"
                                    size="small" 
                                    onClick={handleAddTag}
                                    disabled={!editingTagText.trim() || course.tags.some(tag => tag.text.toLowerCase() === editingTagText.trim().toLowerCase())}
                                    sx={{ ml: 1 }}
                                >
                                    Add Tag
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Typography variant="caption">
                        Click on a tag to edit, or enter a new tag name and select a color to add it.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Course Duration (HH:MM:SS):</Typography>
                    <Box display="flex" alignItems="center">
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                            <InputLabel>Hours</InputLabel>
                            <Select
                                value={course.time.hours}
                                onChange={(e) => handleTimeChange('hours', e.target.value)}
                                label="Hours"
                            >
                                {[...Array(24).keys()].map(i => (
                                    <MenuItem key={i} value={i.toString().padStart(2, '0')}>{i.toString().padStart(2, '0')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant="h6">:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                            <InputLabel>Minutes</InputLabel>
                            <Select
                                value={course.time.minutes}
                                onChange={(e) => handleTimeChange('minutes', e.target.value)}
                                label="Minutes"
                            >
                                {[...Array(60).keys()].map(i => (
                                    <MenuItem key={i} value={i.toString().padStart(2, '0')}>{i.toString().padStart(2, '0')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant="h6">:</Typography>
                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                            <InputLabel>Seconds</InputLabel>
                            <Select
                                value={course.time.seconds}
                                onChange={(e) => handleTimeChange('seconds', e.target.value)}
                                label="Seconds"
                            >
                                {[...Array(60).keys()].map(i => (
                                    <MenuItem key={i} value={i.toString().padStart(2, '0')}>{i.toString().padStart(2, '0')}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {errors.time && <Typography color="error">{errors.time}</Typography>}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Course Description"
                        value={course.description}
                        onChange={(e) => setCourse({ ...course, description: e.target.value })}
                        error={!!errors.description}
                        helperText={errors.description}
                    />
                </Grid>
                <Grid item xs={12}>
                    <BannerUploadAndDisplay
                        onImageSelect={(file) => setSelectedBanner(file)}
                        currentImageFile={selectedBanner}
                    />
                    <Typography variant="caption">
                        A banner image is optional but can make your course more attractive. Recommended size: 1200x800 pixels.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ my: 4 }} /> {/* Increased vertical margin */}
                    <Typography variant="h5" gutterBottom sx={{ mt: 4 }}> {/* Added top margin */}
                        Add Module
                    </Typography>
                    <TextField
                        fullWidth
                        label="Module Title"
                        value={currentModule.title}
                        onChange={(e) => setCurrentModule({...currentModule, title: e.target.value})}
                        error={!!errors.module}
                        helperText={errors.module}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        Add Chapter to Current Module
                    </Typography>
                    <TextField
                        fullWidth
                        label="Chapter Title"
                        value={chapterTitle}
                        onChange={(e) => setChapterTitle(e.target.value)}
                        error={!!errors.title}
                        helperText={errors.title}
                    />
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Chapter Type</InputLabel>
                        <Select
                            value={chapterType}
                            onChange={handleChapterTypeChange}
                            label="Chapter Type"
                        >
                            <MenuItem value="reading">Reading</MenuItem>
                            <MenuItem value="quiz">Quiz</MenuItem>
                            <MenuItem value="video">Video</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {chapterType === 'reading' && (
                        <Box>
                            <RichTextEditor
                                key={editorKey}
                                initialValue=""
                                onChange={(content) => setChapterContent(content)}
                            />
                            {errors.content && <Typography color="error">{errors.content}</Typography>}
                        </Box>
                    )}
                    {chapterType === 'quiz' && (
                        <Box>
                            {quizQuestions.map((question, index) => (
                                <Paper key={index} elevation={2} sx={{ p: 2, mb: 2 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', pt: 4 }}>
                                        <IconButton
                                            onClick={() => handleRemoveQuizQuestion(index)}
                                            disabled={quizQuestions.length === 1}
                                            color="error"
                                            sx={{ position: 'absolute', top: -10, right: -10 }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                        <FormControl fullWidth sx={{ mb: 2 }}>
                                            <InputLabel>Question Type</InputLabel>
                                            <Select
                                                value={question.type}
                                                onChange={(e) => handleQuizQuestionChange(index, 'type', e.target.value)}
                                                label="Question Type"
                                            >
                                                <MenuItem value="MCQ">Multiple Choice</MenuItem>
                                                <MenuItem value="true-false">True/False</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            label="Question"
                                            value={question.question}
                                            onChange={(e) => handleQuizQuestionChange(index, 'question', e.target.value)}
                                            sx={{ mb: 2 }}
                                        />
                                        {question.type === 'MCQ' && (
                                            <>
                                                {question.options.map((option, optIndex) => (
                                                    <TextField
                                                        key={optIndex}
                                                        fullWidth
                                                        label={`Option ${optIndex + 1}`}
                                                        value={option}
                                                        onChange={(e) => handleQuizQuestionChange(index, 'option', { index: optIndex, text: e.target.value })}
                                                        sx={{ mb: 1 }}
                                                    />
                                                ))}
                                                <Button
                                                    startIcon={<AddIcon />}
                                                    onClick={() => handleQuizQuestionChange(index, 'addOption')}
                                                    disabled={question.options.some(opt => !opt.trim())}
                                                    sx={{ mb: 2 }}
                                                >
                                                    Add Option
                                                </Button>
                                                <FormControl fullWidth sx={{ mb: 2 }}>
                                                    <InputLabel>Correct Answer</InputLabel>
                                                    <Select
                                                        value={question.correctAnswer}
                                                        onChange={(e) => handleQuizQuestionChange(index, 'correctAnswer', e.target.value)}
                                                        label="Correct Answer"
                                                    >
                                                        {question.options.map((option, optIndex) => (
                                                            <MenuItem key={optIndex} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </>
                                        )}
                                        {question.type === 'true-false' && (
                                            <FormControl fullWidth sx={{ mb: 2 }}>
                                                <InputLabel>Correct Answer</InputLabel>
                                                <Select
                                                    value={question.correctAnswer}
                                                    onChange={(e) => handleQuizQuestionChange(index, 'correctAnswer', e.target.value)}
                                                    label="Correct Answer"
                                                >
                                                    <MenuItem value="true">True</MenuItem>
                                                    <MenuItem value="false">False</MenuItem>
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Box>
                                </Paper>
                            ))}
                            {errors.questions && (
                                <Typography color="error" sx={{ mt: 1 }}>
                                    {Array.isArray(errors.questions) 
                                        ? errors.questions.map((error, index) => <div key={index}>{error}</div>)
                                        : errors.questions
                                    }
                                </Typography>
                            )}
                            <Button startIcon={<AddIcon />} onClick={handleAddQuizQuestion}>
                                Add Another Question
                            </Button>
                        </Box>
                    )}
                    {chapterType === 'video' && (
                        <Box>
                            <TextField
                                fullWidth
                                label="YouTube URL"
                                value={chapterContent}
                                onChange={(e) => setChapterContent(e.target.value)}
                                error={!!errors.content}
                                helperText={errors.content || "Please enter a valid YouTube video URL (e.g., https://www.youtube.com/watch?v=dQw4w9WgXcQ)"}
                            />
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                        <Button variant="contained" onClick={handleAddChapter}>
                            Add Chapter to Module
                        </Button>
                        <Button variant="contained" onClick={handleAddModule}>
                            Add Module to Course
                        </Button>
                        <Button variant="contained" onClick={handleAddCourse} disabled={isSubmitting}>
                            {isSubmitting ? 'Adding Course...' : 'Add Course'}
                        </Button>
                    </Box>
                </Grid>
                {errors.modules && (
                    <Grid item xs={12}>
                        <Typography color="error">{errors.modules}</Typography>
                    </Grid>
                )}
                {message && (
                    <Grid item xs={12}>
                        <Typography>{message}</Typography>
                    </Grid>
                )}
            </Grid>
            <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)}>
                <DialogTitle>Confirm Add Course</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to add this course?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmAddCourse} color="primary">
                        Yes, Add Course
                    </Button>
                    <Button onClick={() => setShowConfirmation(false)} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CourseCreator;