import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getCourseById, updateChapterOrder } from '../../services/CourseService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QuizIcon from '@mui/icons-material/Quiz';

const CourseDetail = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedModules, setExpandedModules] = useState({});
  const [chapterOrder, setChapterOrder] = useState({});
  const [chaptersLocked, setChaptersLocked] = useState(true);
  const location = useLocation();
  const [showJson, setShowJson] = useState(false);
  const [jsonData, setJsonData] = useState('');

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const moduleId = source.droppableId;
    const newChapterOrder = Array.from(chapterOrder[moduleId]);
    newChapterOrder.splice(source.index, 1);
    newChapterOrder.splice(destination.index, 0, draggableId);

    setChapterOrder(prev => ({
      ...prev,
      [moduleId]: newChapterOrder
    }));

    try {
      await updateChapterOrder(courseId, moduleId, newChapterOrder);
    } catch (error) {
      console.error('Error updating chapter order:', error);
      setChapterOrder(prev => ({
        ...prev,
        [moduleId]: chapterOrder[moduleId]
      }));
    }
  };

  const toggleChaptersLock = () => {
    setChaptersLocked(!chaptersLocked);
  };

  const toggleModule = (moduleId) => {
    setExpandedModules(prev => ({
      ...prev,
      [moduleId]: !prev[moduleId]
    }));
  };

  const handleChapterClick = (module, chapter) => {
    navigate(`/courses/${courseId}/chapters/${chapter.id}`, { state: { chapter } });
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourse = async () => {
      if (!course) {
        setLoading(true);
        try {
          let courseData;
          if (location.state?.course) {
            courseData = location.state.course;
          } else {
            courseData = await getCourseById(courseId);
          }
          setCourse(courseData);
          
          const initialExpandedState = courseData.modules.reduce((acc, module) => {
            acc[module.id] = false;
            return acc;
          }, {});
          setExpandedModules(initialExpandedState);
          
          const initialChapterOrder = courseData.modules.reduce((acc, module) => {
            acc[module.id] = module.chapters.map(chapter => chapter.id);
            return acc;
          }, {});
          setChapterOrder(initialChapterOrder);
          setJsonData(JSON.stringify(courseData, null, 2));
        } catch (error) {
          console.error('Error fetching course:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCourse();
  }, [courseId, course, location.state]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!course) {
    return <Typography>Course not found</Typography>;
  }

  const toggleJsonView = () => {
    if (!showJson) {
      const formattedJson = JSON.stringify(course, null, 2);
      setJsonData(formattedJson);
    }
    setShowJson(!showJson);
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {course.title}
      </Typography>
      <Typography variant="body1" paragraph>
        {course.description}
      </Typography>
      <Box sx={{ mb: 2 }}>
        {course.tags && course.tags.map((tag, index) => (
          <Chip key={index} label={tag} sx={{ mr: 0.5, mb: 0.5 }} />
        ))}
      </Box>
      <Button
        onClick={toggleChaptersLock}
        variant="contained"
        color={chaptersLocked ? "primary" : "secondary"}
        startIcon={chaptersLocked ? <LockIcon /> : <LockOpenIcon />}
        sx={{ mb: 2 }}
      >
        {chaptersLocked ? "Unlock Chapters" : "Lock Chapters"}
      </Button>
      <DragDropContext onDragEnd={onDragEnd}>
        {course.modules.map((module) => (
          <Accordion
            key={module.id}
            expanded={expandedModules[module.id]}
            onChange={() => toggleModule(module.id)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{module.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Droppable droppableId={module.id} isDropDisabled={chaptersLocked}>
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {chapterOrder[module.id] && chapterOrder[module.id].map((chapterId, index) => {
                      const chapter = module.chapters.find(c => c.id === chapterId);
                      if (!chapter) return null; // Skip if chapter not found
                      return (
                        <Draggable
                          key={chapter.id}
                          draggableId={chapter.id}
                          index={index}
                          isDragDisabled={chaptersLocked}
                        >
                          {(provided) => (
                            <ListItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              button
                              onClick={() => handleChapterClick(module, chapter)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <ListItemIcon>
                                <DragIndicatorIcon />
                              </ListItemIcon>
                              <ListItemText primary={chapter.title} />
                              <ListItemIcon>
                                {chapter.type === 'video' && <VideoLibraryIcon />}
                                {chapter.type === 'reading' && <MenuBookIcon />}
                                {chapter.type === 'quiz' && <QuizIcon />}
                              </ListItemIcon>
                            </ListItem>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </AccordionDetails>
          </Accordion>
        ))}
      </DragDropContext>
      <Box sx={{ mt: 4 }}>
        <Button
          variant="outlined"
          onClick={toggleJsonView}
          sx={{ mb: 2 }}
        >
          {showJson ? 'Hide JSON' : 'Show JSON'}
        </Button>
        {showJson && (
          <Paper elevation={3} sx={{ p: 2, maxHeight: '400px', overflow: 'auto' }}>
            <pre>{jsonData}</pre>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default CourseDetail;