import { MobXProviderContext } from "mobx-react";
import React, { useState, useEffect, useContext } from "react";

const CompaniesScreen = () => {
  const { domainStore } = useContext(MobXProviderContext);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companies = await domainStore.userStore.getCompanies();
        setCompanies(companies);

        const defaultCompany = domainStore.companyStore.selectedCompany;
        setSelectedCompany(defaultCompany ? defaultCompany.id : "");
      } catch (error) {
        console.error("Error fetching companies:", error);
        setCompanies([]);
      }
    };

    fetchCompanies();
  }, [domainStore]);

  const handleChange = (event) => {
    const selectedCompanyId = event.target.value;
    setSelectedCompany(selectedCompanyId);
    const company = companies.find(
      (company) => company.id === selectedCompanyId
    );
    if (company) {
      domainStore.companyStore.setSelectedCompany(company);
    }
  };

  return (
    <div>
      <h1>Companies Screen</h1>
      <select value={selectedCompany || ""} onChange={handleChange}>
        {(selectedCompany === "" || selectedCompany === undefined) && (
          <option value="" disabled>
            No Company Selected
          </option>
        )}
        {companies.map((company) => (
          <option key={company.id} value={company.id}>
            {company.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CompaniesScreen;
