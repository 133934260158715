import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import YouTubeEmbed from '../../components/code/YoutubeEmbed';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const VideoPage = ({ chapter, onBack, onNextChapter }) => {
    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                {chapter.title}
            </Typography>
            {chapter.content && (
                <Box sx={{ my: 4 }}>
                    <YouTubeEmbed url={chapter.content} />
                </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button variant="outlined" onClick={onBack} startIcon={<ChevronLeftIcon />}>
                    Back to Course
                </Button>
                <Button variant="contained" onClick={onNextChapter} endIcon={<ChevronRightIcon />}>
                    Next Chapter
                </Button>
            </Box>
        </Container>
    );
};

export default VideoPage;