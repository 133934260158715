import React, { useState } from "react";
import ChatSelector from "../../components/code/chat_screen/ChatSelector";
import ChatRoom from "../../components/code/chat_screen/ChatRoom";

const ChatScreen = ({}) => {
  const [user_to_chat_to, setUserToChatTo] = useState(null);

  //const [company, setCompany] = useState(null);
  //const [chat_is_selected, setChatIsSelected] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        flex: 1,
        backgroundColor: "lightgrey",
      }}
    >
      <ChatSelector
        setUserToChatTo={setUserToChatTo}
        userToChatTo={user_to_chat_to}
      />
      <ChatRoom userToChatWith={user_to_chat_to} />
    </div>
  );
};

export default ChatScreen;
