import React from 'react';
import CourseCreator from '../../components/code/CourseCreator';

const CourseCreatorScreen = () => {
    return (
        <div style={{ padding: '16px' }}>
            <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>Create a New Course</h1>
            <CourseCreator />
        </div>
    );
};

export default CourseCreatorScreen;
