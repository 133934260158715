import { db, auth } from "../firebase"; // Assuming you have these imports
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

/*
@brief  sets the last message and current notifications in the chatroom

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company
@param  userID  The ID associated with a specific user
@param  lastMsg  The last message to be set on the chatroom
@param  notifications  the number of notifications to be set on the specified user
*/
export const setChatroomState = async (
  companyID,
  chatroomID,
  userID,
  lastMsg,
  notifications
) => {
  try {
    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const roomIndex = chat_rooms.findIndex(
      (chat_room) => chat_room.chatroomID === chatroomID
    );

    const uid = userID;

    let room = chat_rooms[roomIndex];
    if (room.chatroomStatus1.uid == uid) {
      room.chatroomStatus1.unreadMessages = notifications;
      room.lastMessage = lastMsg;
      chat_rooms[roomIndex] = room;
      await updateDoc(company.ref, { chatRooms: chat_rooms });
    }
    if (room.chatroomStatus2.uid == uid) {
      room.chatroomStatus2.unreadMessages = notifications;
      room.lastMessage = lastMsg;
      chat_rooms[roomIndex] = room;
      await updateDoc(company.ref, { chatRooms: chat_rooms });
    }
  } catch (error) {
    throw Error(error);
  }
};

/*
@brief  gets the last message and current notifications in the chatroom

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company
@param  userID  The ID associated with a specific user

@return  returns an object containing the last message and the amount of notifications for the specified user
*/
export const getChatroomState = async (companyID, chatroomID, userID) => {
  try {
    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const room = chat_rooms.find(
      (chat_room) => chat_room.chatroomID === chatroomID
    );

    let unread;
    let lastMsg;

    if (room.chatroomStatus1.uid === userID) {
      unread = room.chatroomStatus1.unreadMessages;
    }
    if (room.chatroomStatus2.uid === userID) {
      unread = room.chatroomStatus2.unreadMessages;
    }
    lastMsg = room.lastMessage;
    return { unreadMessages: unread, lastMessage: lastMsg };
  } catch (error) {
    throw Error(error);
  }
};

/*
@brief  gets the last message sent to the chatroom

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company

@return  returns the string value of the last message sent to the chatroom
*/
export const getLastMessage = async (companyID, chatroomID) => {
  try {
    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const room = chat_rooms.find(
      (chat_room) => chat_room.chatroomID === chatroomID
    );
    return room.lastMessage;
  } catch (error) {
    throw Error(error);
  }
};

/*
@brief  gets the last message sent to the chatroom

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company
@param  message  a string containing the message to be set as the last message

@return  returns the string value of the last message sent to the chatroom
*/
export const setLastMessage = async (companyID, chatroomID, message) => {
  try {
    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const roomIndex = chat_rooms.findIndex(
      (chat_room) => chat_room.chatroomID === chatroomID
    );
    chat_rooms[roomIndex].lastMessage = message;
    await updateDoc(company.ref, { chatRooms: chat_rooms });
  } catch (error) {
    throw Error(error);
  }
};

/*
@brief  gets the status of the other user within a chatroom to check if they are actively in the chatroom

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company

@return  returns a boolean that is true if the other user is in the chatroom, and false otherwise
*/
export const getInChat = async (companyID, chatroomID, userID) => {
  const uid = userID;

  try {
    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const roomIndex = chat_rooms.findIndex(
      (chat_room) => chat_room.chatroomID === chatroomID
    );

    let room = chat_rooms[roomIndex];
    if (room.chatroomStatus1.uid === uid) {
      return room.chatroomStatus1.inChat;
    }
    if (room.chatroomStatus2.uid === uid) {
      return room.chatroomStatus2.inChat;
    }
  } catch (error) {
    throw Error(error);
  }
};

/*
@brief  sets whether the current user is in the specified chatroom or not

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company
@param  inChat  A boolean to determine whether the current user is in the chat or not
*/
export const setInChat = async (companyID, chatroomID, inChat) => {
  const uid = auth.currentUser.uid;

  try {
    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const roomIndex = chat_rooms.findIndex(
      (chat_room) => chat_room.chatroomID === chatroomID
    );

    let room = chat_rooms[roomIndex];
    if (room.chatroomStatus1.uid === uid) {
      if (room.chatroomStatus1.inChat !== inChat) {
        room.chatroomStatus1.inChat = inChat;
        chat_rooms[roomIndex] = room;
        await updateDoc(company.ref, { chatRooms: chat_rooms });
      }
    }
    if (room.chatroomStatus2.uid === uid) {
      if (room.chatroomStatus2.inChat !== inChat) {
        room.chatroomStatus2.inChat = inChat;
        chat_rooms[roomIndex] = room;
        await updateDoc(company.ref, { chatRooms: chat_rooms });
      }
    }
    await setTypingStatusOfCurrentUser(companyID, chatroomID, false);
  } catch (error) {
    throw Error(error);
  }
};

/*
@brief  gets the number of current notifications for a specified chatroom 

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company

@return  the number of unread messages/notifications for the specified chatroom
*/
export const getChatroomNotifications = async (
  companyID,
  chatroomID,
  userID
) => {
  try {
    const currentUserID = userID;

    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const room = chat_rooms.find(
      (chat_room) => chat_room.chatroomID === chatroomID
    );

    if (room.chatroomStatus1.uid === currentUserID) {
      return room.chatroomStatus1.unreadMessages;
    }
    if (room.chatroomStatus2.uid === currentUserID) {
      return room.chatroomStatus2.unreadMessages;
    }
  } catch (error) {
    throw Error(error);
  }
};

/*
@brief  A function which sets the number of chatroom notifications for a specified chatroom

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company
@param  numMessages  The number of unreadMessages to set
*/
export const setChatroomNotifications = async (
  companyID,
  chatroomID,
  userID,
  numMessages
) => {
  try {
    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const roomIndex = chat_rooms.findIndex(
      (chat_room) => chat_room.chatroomID === chatroomID
    );

    const uid = userID;

    let room = chat_rooms[roomIndex];
    if (room.chatroomStatus1.uid === uid) {
      room.chatroomStatus1.unreadMessages = numMessages;
      chat_rooms[roomIndex] = room;
      await updateDoc(company.ref, { chatRooms: chat_rooms });
    }
    if (room.chatroomStatus2.uid === uid) {
      room.chatroomStatus2.unreadMessages = numMessages;
      chat_rooms[roomIndex] = room;
      await updateDoc(company.ref, { chatRooms: chat_rooms });
    }
  } catch (error) {
    throw Error(error);
  }
};

/*
@brief  A function which gets the typing status of the users in 
the chatroom besides the current one. If somebody is typing, this will return true

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company

@return  returns a boolean with the typing status of the specified chatroom
*/
export const getTypingStatusOfOtherUserInChatroom = async (
  companyID,
  chatroomID
) => {
  try {
    const currentUserID = auth.currentUser.uid;

    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const room = chat_rooms.find(
      (chat_room) => chat_room.chatroomID === chatroomID
    );

    if (room.chatroomStatus1.uid !== currentUserID) {
      return room.chatroomStatus1.typing;
    }
    if (room.chatroomStatus2.uid !== currentUserID) {
      return room.chatroomStatus2.typing;
    }
  } catch (error) {
    throw Error(error);
  }
};

/*
@brief  A function which sets the typing status of the current user

@param  companyID  The ID associated with the specified company where the chatrooms are located 
@param  chatroomID  The ID associated with the specified chatroom within the company
@param 
*/
export const setTypingStatusOfCurrentUser = async (
  companyID,
  chatroomID,
  status
) => {
  try {
    const q = query(
      collection(db, "companies"),
      where("companyID", "==", companyID)
    );
    const companySnapshot = await Promise.resolve(getDocs(q));
    const company = companySnapshot.docs[0];

    let chat_rooms = company.data().chatRooms;

    const roomIndex = chat_rooms.findIndex(
      (chat_room) => chat_room.chatroomID === chatroomID
    );

    const uid = auth.currentUser.uid;

    // implement code to update typing status
    let room = chat_rooms[roomIndex];
    if (room.chatroomStatus1.uid === uid) {
      room.chatroomStatus1.typing = status;
      chat_rooms[roomIndex] = room;
      await updateDoc(company.ref, { chatRooms: chat_rooms });
    }
    if (room.chatroomStatus2.uid === uid) {
      room.chatroomStatus2.typing = status;
      chat_rooms[roomIndex] = room;
      await updateDoc(company.ref, { chatRooms: chat_rooms });
    }
  } catch (error) {
    throw Error(error);
  }
};

export const handleRouteChange = async (company, userID) => {
  if (!company.id || !userID) {
    return;
  }
  const q = query(
    collection(db, "companies"),
    where("companyID", "==", company.id)
  );
  const companySnapshot = await getDocs(q);
  const cmpny = companySnapshot.docs[0];

  let chat_rooms = cmpny.data().chatRooms;
  for (const chat_room of chat_rooms) {
    if (chat_room.chatroomID.includes(userID)) {
      await setInChat(company.id, chat_room.chatroomID, false);
    }
  }
};
