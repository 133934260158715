import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { User } from "../models/User";

export const getCurrentUserInfo = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      unsubscribe();
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            resolve(userDoc.data());
          } else {
            reject(new Error("User document does not exist"));
          }
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error("No user is currently logged in"));
      }
    });
  });
};

export const getUserInfos = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "users"));
    const users = querySnapshot.docs.map((doc) => ({
      uid: doc.id,
      ...doc.data(),
    }));
    return users;
  } catch (error) {
    throw error;
  }
};

export const getUserByID = async (userID) => {
  const q = doc(db, "users", userID);
  const querySnapshot = await getDoc(q);
  if (!querySnapshot.exists()) {
    console.error(`No user found with ID: ${userID}`);
    return;
  }
  
  const user = new User({
    id: querySnapshot.data().uid,
    email: querySnapshot.data().email,
    firstName: querySnapshot.data().firstName,
    lastName: querySnapshot.data().lastName,
    contactPhone: querySnapshot.data().phoneNumber,
    defaultCompany: querySnapshot.data().defaultCompany,
    companies: [],
  });
  return user;
};

/*
@brief  fetches an array of all the Users associated with the userID list

@param  userIDs  An array of userIDs

@return  returns an array of instances of the User class corresponding to the userIDs list
*/
export const getUsersByID = async (userIDs) => {
  try {
    const users = Promise.all(
      userIDs.map(async (userID) => {
        const user = await getUserByID(userID);
        return user;
      })
    );

    return users;
  } catch (error) {
    console.error("Error fetching users by ID:");
  }
};

export const setUserRole = async (userID, role, companyID) => {
  // Parameter validation
  if (!userID || !role) {
    console.error("Invalid parameters: userID and role are required.");
    return;
  }

  try {
    const userQuery = query(
      collection(db, "companyUsers"),
      where("uid", "==", userID),
      where("companyID", "==", companyID)
    );
    const querySnapshot = await getDocs(userQuery);

    if (!querySnapshot.empty) {
      // Assuming only one document will match, update the first document found
      querySnapshot.forEach(async (document) => {
        await updateDoc(document.ref, { role: role });
      });
    } else {
      // Document does not exist, log an error or handle accordingly
      console.error(
        `No document found for user ${userID}, unable to update role.`
      );
    }
  } catch (error) {
    console.error("Failed to set user role:");
  }
};

/*
@brief  A function to get an object of data associated with the specified user ID

@param userID  A string to represent the userID of the user to be fetched

@return  returns an object of data corresponding to the userID
*/
export const getUserObjectByID = async (userID) => {
  try {
    const q = query(collection(db, "users"), where("uid", "==", userID));

    const querySnapshot = await getDocs(q);
    let user;
    querySnapshot.forEach((doc) => {
      user = doc.data();
    });
    return user;
  } catch (error) {
    console.error("Error getting user object by ID:");
  }
};

/*
@brief  fetches an array of all the objects associated with the userID list

@param  userIDs  An array of userIDs

@return  returns an array of objects corresponding to the userIDs list
*/
export const getUserObjectsByID = async (userIDs) => {
  try {
    const users = await Promise.all(
      userIDs.map(async (userID) => {
        const user = await getUserObjectByID(userID);
        return user;
      })
    );
    return users;
  } catch (error) {
    console.error("Error fetching user objects by ID:");
  }
};
export const setUserIsSuspended = async (userID, isSuspended, companyID) => {
  // Parameter validation
  if (!userID || !companyID) {
    console.error("Invalid parameters: userID and companyID are required.");
    return;
  }

  try {
    // Fetch and update in companyUsers collection
    const userQuery = query(
      collection(db, "companyUsers"),
      where("uid", "==", userID),
      where("companyID", "==", companyID)
    );
    const querySnapshot = await getDocs(userQuery);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (document) => {
        await updateDoc(document.ref, { isSuspended: isSuspended });
      });
    } else {
      console.error(
        `No document found for user ${userID} in companyUsers, unable to update.`
      );
    }

    // Fetch and update in users collection
    const userDocRef = doc(db, "users", userID);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      if (userData.companies) {
        // Remove companyID from companies array
        let updatedCompanies;
        if (isSuspended && userData.companies.includes(companyID)) {
          updatedCompanies = userData.companies.filter(
            (id) => id !== companyID
          );
        } else if (!isSuspended && !userData.companies.includes(companyID)) {
          updatedCompanies = [...userData.companies, companyID];
        }
        if (updatedCompanies === undefined) return;
        await updateDoc(userDocRef, { companies: updatedCompanies });
      }
    } else {
      console.error(
        `No document found for user ${userID} in users collection, unable to update companies.`
      );
    }
  } catch (error) {
    console.error("Error updating user status and companies");
  }
};

export const setUserDefaultCompany = async (userID, companyID) => {
  // Parameter validation
  if (!userID) {
    console.error("Invalid parameters: userID and companyID are required.");
    return;
  }

  try {
    const userDocRef = doc(db, "users", userID);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      await updateDoc(userDocRef, { defaultCompany: companyID });
    } else {
      console.error(
        `No document found for user ${userID} in users collection, unable to update default company.`
      );
    }
  } catch (error) {
    console.error("Error updating user default company:");
  }
};


export const updateUserProfile = async (data) => {
  // Parameter validation
  if (!data) {
    console.error("Invalid parameters: userID and data are required.");
    return;
  }
  try {
    const userDocRef = doc(db, "users", data.id);
    const updatedData = {uid: data.id, email: data.email, firstName: data.firstName, lastName: data.lastName, phoneNumber: data.contactPhone};
    await updateDoc(userDocRef, updatedData);
  } catch (error) {
    console.error("Error updating user profile:");
  }
};

export const checkEmailUniqueness = async (email) => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('email', '==', email));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty;
};