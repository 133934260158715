import React, { useState, useEffect, useContext } from "react";
import { db, auth } from "../../../firebase";
import { useLocation } from "react-router-dom";
import { getCompanyUsersForCompanyExceptCurrent } from "../../../queries/companyQueries";
import {
  query,
  where,
  collection,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import ChatUserCard from "./ChatUserCard";
import classes from "../../styling/chat_screen/ChatSelector.module.css";
import {
  getChatroomNotifications,
  setChatroomNotifications,
  setInChat,
  handleRouteChange,
  getLastMessage,
  getChatroomState,
} from "../../../queries/chatQueries";
import { onAuthStateChanged } from "firebase/auth";
import { MobXProviderContext } from "mobx-react";

const ChatSelector = ({ setUserToChatTo, userToChatTo }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { domainStore } = useContext(MobXProviderContext);
  const location = useLocation();

  /*
  @brief  gets an array of objects containing the information of all the users in the company

  @param  companyID  the ID of the company to grab user info from

  @return  returns an array of objects containing the information of each user in the company
  */
  const getUsersInCompany = async (company_id) => {
    if (!auth.currentUser) {
      return [];
    }

    try {
      // gets the list of company users from the databases
      const company_usrs = await Promise.all(
        await getCompanyUsersForCompanyExceptCurrent(company_id)
      );
      const q = query(
        collection(db, "companies"),
        where("companyID", "==", company_id)
      );
      const snapshot = await getDocs(q);
      const queryData = snapshot.docs.map((doc) => doc.data().chatRooms);
      const allChatrooms = queryData[0];

      let n = 0;
      let userObjectList = [];

      await Promise.resolve(auth.authStateReady());

      // finds the company users that correspond to the chatrooms,
      // and adds to a list of user objects for each chatroom so that each user's
      // chatroom can be displayed on the screen
      for (const chatRoom of allChatrooms) {
        for (const company_usr of company_usrs) {
          if (
            chatRoom.chatroomID.includes(company_usr.id) &&
            chatRoom.chatroomID.includes(auth.currentUser.uid)
          ) {
            let unread = 0;
            if (chatRoom.chatroomStatus1.uid === auth.currentUser.uid) {
              unread = chatRoom.chatroomStatus1.unreadMessages;
            }
            if (chatRoom.chatroomStatus2.uid === auth.currentUser.uid) {
              unread = chatRoom.chatroomStatus2.unreadMessages;
            }

            const userObject = {
              user: company_usr.user,
              key: n,
              role: company_usr.role,
              chatRoom: chatRoom.chatroomID,
              unreadMessages: unread,
              lastMessage: chatRoom.lastMessage,
            };

            n++;
            userObjectList.push(userObject);
          }
        }
      }

      return userObjectList;
    } catch (error) {
      throw Error(error);
    }
  };

  // loads the user information for each user in the company to then be displayed
  useEffect(() => {
    const company = domainStore.companyStore.getSelectedCompany();
    if (!company.id) {
      setIsLoading(false);
      return;
    }
    if (company) {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        const usrs = await getUsersInCompany(company.id);
        setUsers(usrs);
        setIsLoading(false);
      });
      return () => unsubscribe();
    }
  }, [domainStore.companyStore.selectedCompany, isLoading]);

  useEffect(() => {
    const company = domainStore.companyStore.getSelectedCompany();
    if (!company.id) return;
    const q = query(
      collection(db, "companies"),
      where("companyID", "==", company.id)
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      if (users.length > 0) {
        let newUsers = [...users];

        for (let i = 0; i < newUsers.length; i++) {
          let user = newUsers[i];
          const { unreadMessages, lastMessage } = await Promise.resolve(
            getChatroomState(company.id, user.chatRoom, auth.currentUser.uid)
          );

          let newUser = { ...user };
          newUser.unreadMessages = unreadMessages;
          newUser.lastMessage = lastMessage;
          newUsers[i] = newUser;
        }
        if (newUsers === users) return;
        setUsers(newUsers);
      }
    });

    return () => unsubscribe();
  }, [domainStore.companyStore.selectedCompany, isLoading]);

  // sets the user to not be in the chat if the tab is closed
  useEffect(() => {
    const company = domainStore.companyStore.getSelectedCompany;
    if (!company.id) return;
    const cleanup = async () => {
      await handleRouteChange(
        domainStore.companyStore.getSelectedCompany(),
        domainStore.userStore.currentUser.id
      );
    };

    window.addEventListener("beforeunload", cleanup);

    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  }, [domainStore.companyStore.selectedCompany, userToChatTo]);

  // Run cleanup code when navigating away from /chat
  useEffect(() => {
    const company = domainStore.companyStore.getSelectedCompany();
    if (!company.id) return;
    return () => {
      handleRouteChange(
        domainStore.companyStore.getSelectedCompany(),
        domainStore.userStore.currentUser.id
      ); // Call the function when the component is unmounted
    };
  }, [
    domainStore.companyStore.selectedCompany,
    location.pathname,
    domainStore,
  ]); // Dependency array includes location.pathname

  const onClickHandle = async (user) => {
    const companyID = domainStore.companyStore.selectedCompany.id;
    if (userToChatTo) {
      await setInChat(companyID, userToChatTo.chatRoom, false);
    }
    setUserToChatTo(user);

    await setChatroomNotifications(
      companyID,
      user.chatRoom,
      auth.currentUser.uid,
      0
    );

    await setInChat(companyID, user.chatRoom, true);
  };

  return (
    <div className={classes.outerContainer}>
      <header className={classes.header}>
        <h1>Chats</h1>
      </header>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <ul className={classes.listContainer}>
          {users && users.length > 0 ? (
            users.map((user) => (
              <ChatUserCard
                key={user.key}
                userInfo={user}
                onClick={onClickHandle}
              />
            ))
          ) : (
            <p>No users found! Check selected company!</p>
          )}
        </ul>
      )}
    </div>
  );
};

export default ChatSelector;
