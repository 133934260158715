import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getCourseById } from '../../services/CourseService';
import VideoPage from './VideoPage';
import ReadingPage from './ReadingPage';
import QuizPage from './QuizPage';
import { CircularProgress, Box } from '@mui/material';

const ChapterPage = () => {
  const { courseId, chapterId } = useParams();
  const location = useLocation();
  const [chapter, setChapter] = useState(location.state?.chapter || null);
  const [loading, setLoading] = useState(!location.state?.chapter);
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);

  useEffect(() => {
    const fetchCourseAndChapter = async () => {
      setLoading(true);
      try {
        const courseData = await getCourseById(courseId);
        setCourse(courseData);
        if (!chapter || chapter.id !== chapterId) {
          const chapterData = courseData.modules
            .flatMap(module => module.chapters)
            .find(ch => ch.id === chapterId);
          setChapter(chapterData);
        }
      } catch (error) {
        console.error('Error fetching course or chapter:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseAndChapter();
  }, [courseId, chapterId, chapter]);

  const handleBackToCourse = () => {
    navigate(`/courses/${courseId}`, { 
      replace: true,
      state: { course }
    });
  };

  const handleNextChapter = () => {
    const allChapters = course.modules.flatMap(module => module.chapters);
    const currentIndex = allChapters.findIndex(ch => ch.id === chapter.id);
    if (currentIndex < allChapters.length - 1) {
      const nextChapter = allChapters[currentIndex + 1];
      navigate(`/courses/${courseId}/chapters/${nextChapter.id}`, {
        replace: true,
        state: { chapter: nextChapter }
      });
    } else {
      // If it's the last chapter, navigate back to the course
      handleBackToCourse();
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!chapter) {
    return null;
  }

  const commonProps = {
    chapter,
    onBack: handleBackToCourse,
    onNextChapter: handleNextChapter,
  };

  switch (chapter.type) {
    case 'video':
      return <VideoPage {...commonProps} />;
    case 'reading':
      return <ReadingPage {...commonProps} />;
    case 'quiz':
      return <QuizPage {...commonProps} />;
    default:
      return null;
  }
};

export default ChapterPage;
