import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Card,
  CardContent,
  Fade,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgressBar from '../../components/code/CircularProgressBar';

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
}));

const QuizPage = ({ chapter, onBack, onNextChapter }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [fadeIn, setFadeIn] = useState(true);

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
  };

  const handleSubmitAnswer = () => {
    if (selectedAnswer === chapter.questions[currentQuestionIndex].correctAnswer) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestionIndex + 1;
    if (nextQuestion < chapter.questions.length) {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentQuestionIndex(nextQuestion);
        setSelectedAnswer('');
        setFadeIn(true);
      }, 300);
    } else {
      setShowScore(true);
    }
  };

  const handleExitClick = () => {
    setShowExitConfirmation(true);
  };

  const handleConfirmExit = () => {
    onBack();
  };

  const handleCancelExit = () => {
    setShowExitConfirmation(false);
  };

  if (chapter.questions.length === 0) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          No questions available for this quiz
        </Typography>
        <Button variant="contained" onClick={onBack}>
          Back to Course
        </Button>
      </Container>
    );
  }

  if (showScore) {
    const scorePercentage = (score / chapter.questions.length) * 100;
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <StyledCard>
          <CardContent>
            <Typography variant="h4" component="h1" gutterBottom>
              Quiz Completed
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgressBar value={scorePercentage} />
            </Box>
            <Typography variant="h5" gutterBottom>
              Your score: {score} out of {chapter.questions.length}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              <Button variant="outlined" onClick={onBack} startIcon={<ChevronLeftIcon />}>
                Back to Course
              </Button>
              <Button variant="contained" onClick={onNextChapter} endIcon={<ChevronRightIcon />}>
                Next Chapter
              </Button>
            </Box>
          </CardContent>
        </StyledCard>
      </Container>
    );
  }

  const currentQuestion = chapter.questions[currentQuestionIndex];
  const progress = ((currentQuestionIndex + 1) / chapter.questions.length) * 100;

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {chapter.title} - Quiz
      </Typography>
      <Box sx={{ width: '100%', mb: 4 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Typography variant="subtitle1" gutterBottom>
        Question {currentQuestionIndex + 1} of {chapter.questions.length}
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Score: {score}
      </Typography>
      <Fade in={fadeIn}>
        <StyledCard>
          <CardContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                <Typography variant="h6">{currentQuestion.question}</Typography>
              </FormLabel>
              <RadioGroup
                aria-label="quiz"
                name="quiz"
                value={selectedAnswer}
                onChange={handleAnswerChange}
              >
                {currentQuestion.type === 'MCQ' &&
                  currentQuestion.options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  ))}
                {currentQuestion.type === 'true-false' && (
                  <>
                    <FormControlLabel value="true" control={<Radio />} label="True" />
                    <FormControlLabel value="false" control={<Radio />} label="False" />
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </CardContent>
        </StyledCard>
      </Fade>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button variant="outlined" onClick={handleExitClick} color="secondary">
          Exit Quiz
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmitAnswer}
          disabled={!selectedAnswer}
        >
          {currentQuestionIndex === chapter.questions.length - 1 ? 'Finish Quiz' : 'Next Question'}
        </Button>
      </Box>

      <Dialog
        open={showExitConfirmation}
        onClose={handleCancelExit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Exit"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to exit the quiz? Your progress will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelExit} color="primary">
            No, Continue Quiz
          </Button>
          <Button onClick={handleConfirmExit} color="secondary" autoFocus>
            Yes, Exit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default QuizPage;