import React, { useState } from "react";
import { setDoc, doc } from "firebase/firestore";
import { db, auth } from "../../firebase";
import classes from "../styling/SignUpScreen.module.css";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

const SignUpScreen = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    const nameRegex = /^[a-zA-Z]+$/; // Only letters allowed

    if (!firstName.trim()) newErrors.firstName = "First name is required";
    else if (!nameRegex.test(firstName)) newErrors.firstName = "First name should only contain letters";

    if (!lastName.trim()) newErrors.lastName = "Last name is required";
    else if (!nameRegex.test(lastName)) newErrors.lastName = "Last name should only contain letters";

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) newErrors.email = "Invalid email format";
    const phoneRegex = /^(\+\d{1,3}[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
    if (!phoneRegex.test(phoneNumber)) newErrors.phoneNumber = "Invalid phone number format";
    if (!password) newErrors.password = "Password is required";
    if (password.length < 6) newErrors.password = "Password must be at least 6 characters long";
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSignUp = async () => {
    if (!validateForm()) return;

    try {
      const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredentials.user;
      const url = `https://theoscode.com/verify-email?userId=${encodeURIComponent(user.uid)}`;
      
      await sendEmailVerification(user, {
        url: url,
        handleCodeInApp: true
      });

      // Create a temporary user record
      await setDoc(doc(db, 'tempUsers', user.uid), {
        email: user.email.toLowerCase(),
        uid: user.uid,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        companies: [],
        defaultCompany: '',
      });

      navigate("/verify-email");
    } catch (error) {
      console.error("Error adding document: ", error);
      setErrors({ signUp: error.message });
    }
  };

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
    if (!/^[a-zA-Z]*$/.test(value)) {
      setErrors(prev => ({ ...prev, firstName: "First name should only contain letters" }));
    } else {
      setErrors(prev => ({ ...prev, firstName: null }));
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
    if (!/^[a-zA-Z]*$/.test(value)) {
      setErrors(prev => ({ ...prev, lastName: "Last name should only contain letters" }));
    } else {
      setErrors(prev => ({ ...prev, lastName: null }));
    }
  };

  return (
    <div className={classes.container}>
      <button onClick={() => navigate("/login")} className={classes.backButton}>Back</button>
      <h1 className={classes.title}>Sign Up</h1>
      <div className={classes.inputContainer}>
        <input
          type="text"
          className={`${classes.input} ${errors.firstName ? classes.error : ''}`}
          placeholder="First Name"
          value={firstName}
          onChange={handleFirstNameChange}
        />
        {errors.firstName && <p className={classes.errorText}>{errors.firstName}</p>}
        
        <input
          type="text"
          className={`${classes.input} ${errors.lastName ? classes.error : ''}`}
          placeholder="Last Name"
          value={lastName}
          onChange={handleLastNameChange}
        />
        {errors.lastName && <p className={classes.errorText}>{errors.lastName}</p>}
        
        <input
          type="email"
          className={`${classes.input} ${errors.email ? classes.error : ''}`}
          placeholder="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        {errors.email && <p className={classes.errorText}>{errors.email}</p>}
        
        <input
          type="tel"
          className={`${classes.input} ${errors.phoneNumber ? classes.error : ''}`}
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
        {errors.phoneNumber && <p className={classes.errorText}>{errors.phoneNumber}</p>}
        
        <input
          type="password"
          className={`${classes.input} ${errors.password ? classes.error : ''}`}
          placeholder="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        {errors.password && <p className={classes.errorText}>{errors.password}</p>}
      </div>
      <div className={classes.buttonContainer}>
        <button onClick={handleSignUp} className={classes.button}>
          <p className={classes.buttonOutlineText}>Register</p>
        </button>
      </div>
      {errors.signUp && <p className={classes.errorText}>{errors.signUp}</p>}
    </div>
  );
};

export default SignUpScreen;