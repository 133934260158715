import React, { useEffect, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Placeholder from '@tiptap/extension-placeholder';
import '../styling/RichTextEditor.css';

const RichTextEditor = ({ value, onChange }) => {
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoFile, setVideoFile] = useState(null);

    const editor = useEditor({
        extensions: [
            StarterKit,
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Placeholder.configure({
                placeholder: 'Write something…',
            }),
        ],
        content: value,
        onBlur: ({ editor }) => {
            onChange(editor.getHTML());
        },
    });

    useEffect(() => {
        if (editor) {
            editor.commands.setContent(value);
        }
    }, [value, editor]);

    useEffect(() => {
        return () => {
            if (editor) {
                editor.destroy();
            }
        };
    }, [editor]);

    const handleInsertVideo = () => {
        setShowVideoModal(true);
    };

    const handleVideoUrlChange = (e) => {
        setVideoUrl(e.target.value);
    };

    const handleVideoFileChange = (e) => {
        setVideoFile(e.target.files[0]);
    };

    const handleVideoSubmit = () => {
        if (videoUrl) {
            const videoId = extractYouTubeId(videoUrl);
            if (videoId) {
                const embedHtml = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                editor.chain().focus().insertContent(embedHtml).run();
            }
        } else if (videoFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const videoHtml = `<video width="560" height="315" controls><source src="${e.target.result}" type="${videoFile.type}"></video>`;
                editor.chain().focus().insertContent(videoHtml).run();
            };
            reader.readAsDataURL(videoFile);
        }
        setShowVideoModal(false);
        setVideoUrl('');
        setVideoFile(null);
    };

    const extractYouTubeId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    if (!editor) {
        return null;
    }

    return (
        <div>
            <div className="menu-bar">
                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={editor.isActive('bold') ? 'is-active' : ''}
                >
                    Bold
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={editor.isActive('italic') ? 'is-active' : ''}
                >
                    Italic
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                    H1
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                    H2
                </button>
                <button
                    onClick={handleInsertVideo}
                    className="menu-item"
                >
                    Insert Video
                </button>
            </div>
            <div className="editor-container">
                <EditorContent editor={editor} className="rich-text-editor" />
            </div>
            {showVideoModal && (
                <div className="video-modal">
                    <h3>Insert Video</h3>
                    <input
                        type="text"
                        placeholder="YouTube URL"
                        value={videoUrl}
                        onChange={handleVideoUrlChange}
                    />
                    <p>OR</p>
                    <input
                        type="file"
                        accept="video/*"
                        onChange={handleVideoFileChange}
                    />
                    <button onClick={handleVideoSubmit}>Insert</button>
                    <button onClick={() => setShowVideoModal(false)}>Cancel</button>
                </div>
            )}
        </div>
    );
};

export default RichTextEditor;