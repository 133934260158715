import React from "react";
import { Link } from "react-router-dom";
import classes from "../styling/NavBar.module.css";

const NavItem = ({ path, label, unreadMessages }) => {
  if (path === "/chat") {
    return (
      <li className={classes.unreadMessagesOuterContainer}>
        {unreadMessages > 0 ? (
          <div className={classes.unreadMessagesBubbleContainer}>
            <p className={classes.unreadMessagesText}>{unreadMessages}</p>
          </div>
        ) : (
          <></>
        )}
        <div className={classes.navItem}>
          <Link to={path} className={classes.navLinks}>
            {label}
          </Link>
        </div>
      </li>
    );
  }

  return (
    <li className={classes.navItem}>
      <Link to={path} className={classes.navLinks}>
        {label}
      </Link>
    </li>
  );
};
export default NavItem;
