import React from "react";
import classes from "../../styling/chat_screen/ChatBubble.module.css";

const ChatBubble = ({ messageObject }) => {

  if (messageObject.sent) {
    return (
      <li key={messageObject.key} className={classes.sentOuterContainer}>
        <div className={classes.sentBubble}>
          <p className={classes.sentText}>{messageObject.text}</p>
        </div>
      </li>
    );
  } else {
    return (
      <li key={messageObject.key} className={classes.receivedOuterContainer}>
        <div className={classes.receivedBubble}>
          <p className={classes.receivedText}>{messageObject.text}</p>
        </div>
      </li>
    );
  }
};

export default ChatBubble;
