import React, { useState, useContext, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, storage } from "../../firebase";
import { MobXProviderContext } from "mobx-react";

const UploadAndDisplayImage = () => {
    const { domainStore } = useContext(MobXProviderContext);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        const user = domainStore.userStore.currentUser;
        if (!user) return;
    
        const storedProfilePic = domainStore.userStore.currentUser.profilePicture;
        if (storedProfilePic) {
          setImagePreviewUrl(storedProfilePic);
        } else {
          const imageRef = ref(storage, "profilePic/" + user.id);
    
          getDownloadURL(imageRef)
          .then((url) => {
            domainStore.userStore.setProfilePicture(url);
            setImagePreviewUrl(url);
          })
          .catch((error) => {
            const defaultImage = ref(storage, "profilePic/blank-profile.png");
            getDownloadURL(defaultImage)
              .then((url) => {
                domainStore.userStore.setProfilePicture(url);
                setImagePreviewUrl(url);
              })
              .catch((error) => {
                console.error("Error fetching image:", error);
          });
        });
          
        }
      }, [domainStore.userStore, domainStore.userStore.currentUser, domainStore.userStore.profilePicture, domainStore.userStore.updateFlag]);
    

    const resizeAndCompressImage = (file, maxWidth, maxHeight, quality, callback) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                    (blob) => {
                        if (blob) {
                            callback(new File([blob], file.name, {
                                type: 'image/jpeg',
                                lastModified: Date.now()
                            }));
                        }
                    },
                    'image/jpeg',
                    quality
                );
            }
            img.src = event.target.result;
        }
        reader.readAsDataURL(file);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            resizeAndCompressImage(file, 800, 800, 0.7, (compressedFile) => {
                setSelectedImage(compressedFile);
                const objectUrl = URL.createObjectURL(compressedFile);
                setImagePreviewUrl(objectUrl);
                setError("");
            });
        }
    };

    const uploadImage = async () => {
        if (!selectedImage) {
            setError("Please select an image to upload");
            return;
        }
        try {
            const storageRef = ref(storage, 'profilePic/' + auth.currentUser.uid);
            const snapshot = await uploadBytes(storageRef, selectedImage);
            const downloadURL = await getDownloadURL(snapshot.ref);
            domainStore.userStore.setProfilePicture(downloadURL);
            domainStore.userStore.updateFlag = !domainStore.userStore.updateFlag;
            setError("");
        } catch (uploadError) {
            console.error("Error uploading image:", uploadError);
            setError("Failed to upload image. Please try again.");
        }
    };

    return (
        <div>
            <h1>Upload Profile Picture</h1>
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {error && <p style={{ color: "red" }}>{error}</p>}
            {imagePreviewUrl && (
                <div>
                    <img
                        alt="Profile preview"
                        width={"250px"}
                        src={imagePreviewUrl}
                    />
                    <br /> <br />
                </div>
            )}
            <br />
            <button onClick={uploadImage}>Upload to Firebase</button>
        </div>
    );
};

export default UploadAndDisplayImage;
