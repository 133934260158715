import { db } from '../firebase';
import { collection, getDocs, getDoc, query, orderBy, setDoc, doc, updateDoc } from 'firebase/firestore';

const createSearchableFields = (course) => {
  const { name, description, tags, author } = course;
  const searchableFields = [
    ...name.toLowerCase().split(' '),
    ...description.toLowerCase().split(' '),
    ...tags.map(tag => tag.toLowerCase()),
    ...author.toLowerCase().split(' ')
  ];
  return [...new Set(searchableFields)];
};

export const addCourse = async (course) => {
    const courseRef = doc(collection(db, 'courses'));
    const courseData = {
        name: course.name,
        tags: course.tags,
        time: course.time,
        author: course.author,
        banner: course.banner,
        description: course.description
    };
    courseData.searchableFields = createSearchableFields(courseData);
    await setDoc(courseRef, courseData);

    for (const module of course.modules) {
        const moduleRef = doc(collection(courseRef, 'modules'));
        await setDoc(moduleRef, {
            title: module.title
        });

        for (const chapter of module.chapters) {
            const chapterRef = doc(collection(moduleRef, 'chapters'));
            const chapterData = {
                title: chapter.title,
                type: chapter.type
            };

            if (chapter.type === 'reading' || chapter.type === 'video') {
                chapterData.content = chapter.content;
            } else if (chapter.type === 'quiz') {
                chapterData.questions = chapter.questions;
            }

            await setDoc(chapterRef, chapterData);
        }
    }
};

export const getCourses = async (searchTerm = '', sort = { field: '', order: 'asc' }, selectedTags = []) => {
  try {
    const coursesRef = collection(db, 'courses');
    let q = query(coursesRef);

    if (sort.field) {
      q = query(q, orderBy(sort.field, sort.order));
    }

    const querySnapshot = await getDocs(q);
    let courses = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      courses = courses.filter(course => 
        course.name.toLowerCase().includes(lowerSearchTerm)
      );
    }

    if (selectedTags.length > 0) {
      courses = courses.filter(course => 
        selectedTags.every(tag => 
          course.tags.some(courseTag => 
            (typeof courseTag === 'object' ? courseTag.text : courseTag).toLowerCase() === tag.toLowerCase()
          )
        )
      );
    }

    return courses;
  } catch (error) {
    console.error('Error getting courses:', error);
    throw error;
  }
};

export const getCourseById = async (courseId) => {
    const courseRef = doc(db, 'courses', courseId);
    const courseSnap = await getDoc(courseRef);

    if (!courseSnap.exists()) {
        throw new Error('No such course!');
    }

    const courseData = courseSnap.data();

    // Fetch modules subcollection
    const modulesRef = collection(courseRef, 'modules');
    const modulesSnapshot = await getDocs(modulesRef);
    const modules = await Promise.all(modulesSnapshot.docs.map(async (moduleDoc) => {
        const moduleData = moduleDoc.data();
        
        // Fetch chapters for each module
        const chaptersRef = collection(moduleDoc.ref, 'chapters');
        const chaptersSnapshot = await getDocs(chaptersRef);
        const chapters = chaptersSnapshot.docs.map(chapterDoc => ({
            id: chapterDoc.id,
            ...chapterDoc.data()
        }));

        // Sort chapters based on chapterOrder if it exists
        const sortedChapters = moduleData.chapterOrder
            ? moduleData.chapterOrder.map(chapterId => chapters.find(chapter => chapter.id === chapterId))
            : chapters;

        return {
            id: moduleDoc.id,
            ...moduleData,
            chapters: sortedChapters
        };
    }));

    return { id: courseSnap.id, ...courseData, modules };
};

export const getQuizQuestions = async (courseId, moduleIndex, chapterIndex) => {
    const courseRef = doc(db, 'courses', courseId);
    const modulesRef = collection(courseRef, 'modules');
    const modulesSnapshot = await getDocs(modulesRef);
    const modules = modulesSnapshot.docs;

    if (moduleIndex < 0 || moduleIndex >= modules.length) {
        throw new Error('Invalid module index');
    }

    const moduleData = modules[moduleIndex].data();
    const chaptersRef = collection(modules[moduleIndex].ref, 'chapters');
    const chaptersSnapshot = await getDocs(chaptersRef);
    const chapters = chaptersSnapshot.docs;

    if (chapterIndex < 0 || chapterIndex >= chapters.length) {
        throw new Error('Invalid chapter index');
    }

    const chapterData = chapters[chapterIndex].data();
    
    if (chapterData.type !== 'quiz' || !chapterData.questions) {
        throw new Error('No quiz questions found for this chapter');
    }

    return chapterData.questions;
};

export const updateChapterOrder = async (courseId, moduleId, newOrder) => {
    const courseRef = doc(db, 'courses', courseId);
    const moduleRef = doc(collection(courseRef, 'modules'), moduleId);

    await updateDoc(moduleRef, {
        chapterOrder: newOrder
    });
};

export const getChapterById = async (courseId, moduleId, chapterId) => {
    const courseRef = doc(db, 'courses', courseId);
    const moduleRef = doc(collection(courseRef, 'modules'), moduleId);
    const chapterRef = doc(collection(moduleRef, 'chapters'), chapterId);

    const chapterSnap = await getDoc(chapterRef);

    if (!chapterSnap.exists()) {
        throw new Error('Chapter not found');
    }

    return { id: chapterSnap.id, ...chapterSnap.data() };
};