import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "../styling/NavBar.module.css";
import NavItem from "./NavItem";
import { navItems } from "../../data/navData";
import { getDownloadURL, ref } from "firebase/storage";
import { MobXProviderContext, observer } from "mobx-react";
import { storage, auth, db } from "../../firebase";
import { handleRouteChange } from "../../queries/chatQueries";
import {
  getDocs,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";

const NavBar = observer(() => {
  const { domainStore } = useContext(MobXProviderContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [unreadMessages, setUnreadMessages] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const user = domainStore.userStore.currentUser;
    if (!user) return;

    const storedProfilePic = user.profilePicture;
    if (storedProfilePic) {
      setImageUrl(storedProfilePic);
    } else {
      const imageRef = ref(storage, "profilePic/" + user.id);

      getDownloadURL(imageRef)
        .then((url) => {
          domainStore.userStore.setProfilePicture(url);
          setImageUrl(url);
        })
        .catch((error) => {
          const defaultImage = ref(storage, "profilePic/blank-profile.png");
          getDownloadURL(defaultImage)
            .then((url) => {
              domainStore.userStore.setProfilePicture(url);
              setImageUrl(url);
            })
            .catch((error) => {
              console.error("Error fetching image:", error);
            });
        });
    }
  }, [
    domainStore.userStore,
    domainStore.userStore.currentUser,
    domainStore.userStore.profilePicture,
    domainStore.userStore.updateFlag,
  ]);

  useEffect(() => {
    try {
      if (!domainStore) return;
      const company = domainStore.companyStore.getSelectedCompany();
      if (!company.id) return;

      const user = domainStore.userStore.getCurrentUser();
      if (!user.id) return;

      if (company) {
        const q = query(
          collection(db, "companies"),
          where("companyID", "==", company.id)
        );
        const unsubscribe = onSnapshot(q, async (snapshot) => {
          try {
            const companySnapshot = await Promise.resolve(getDocs(q));
            const cmpny = companySnapshot.docs[0];

            let chat_rooms = cmpny.data().chatRooms;
            if (chat_rooms) {
              let totalUnread = 0;
              for (let chat_room of chat_rooms) {
                if (chat_room.chatroomID.includes(user.id)) {
                  let unread = 0;
                  if (chat_room.chatroomStatus1.uid === user.id) {
                    unread = chat_room.chatroomStatus1.unreadMessages;
                  }
                  if (chat_room.chatroomStatus2.uid === user.id) {
                    unread = chat_room.chatroomStatus2.unreadMessages;
                  }
                  totalUnread += unread;
                }
              }
              setUnreadMessages(totalUnread);
              console.log(totalUnread);
            }
          } catch (error) {
            throw Error(error);
          }
        });

        return () => unsubscribe();
      }
    } catch (error) {
      throw Error(error);
    }
  }, [
    domainStore.companyStore.selectedCompany,
    domainStore.userStore.currentUser,
    domainStore,
  ]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleOptionClick = async (option) => {
    switch (option) {
      case "Profile":
        navigate("/profile");
        break;
      case "Settings":
        navigate("/settings");
        break;
      case "Logout":
        if (location.pathname === "/chat") {
          await handleRouteChange(
            domainStore.companyStore.getSelectedCompany(),
            domainStore.userStore.currentUser.id
          );
        }
        auth.signOut();
        localStorage.clear();
        domainStore.clearStores();
        navigate("/login");
        break;
      case "Companies":
        navigate("/companies");
        break;
      default:
        break;
    }
    setDropdownVisible(false);
  };

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarContainer}>
        <Link to="/" className={classes.navbarLogo}>
          MyApp
        </Link>
        <ul className={classes.navMenu}>
          {navItems.map((item, index) => {
            return (
              <NavItem
                key={index}
                path={item.path}
                label={item.label}
                unreadMessages={unreadMessages}
              />
            );
          })}
        </ul>
        {imageUrl && (
          <div>
            <img
              alt="profile pic"
              src={imageUrl}
              className={classes.profilePic}
              onClick={toggleDropdown}
            />
            <div
              className={`${classes.dropdownMenu} ${
                dropdownVisible ? classes.show : ""
              }`}
            >
              <div
                className={classes.dropdownItem}
                onClick={() => handleOptionClick("Profile")}
              >
                Profile
              </div>
              <div
                className={classes.dropdownItem}
                onClick={() => handleOptionClick("Settings")}
              >
                Settings
              </div>
              <div
                className={classes.dropdownItem}
                onClick={() => handleOptionClick("Companies")}
              >
                Companies
              </div>
              <div
                className={classes.dropdownItem}
                onClick={() => handleOptionClick("Logout")}
              >
                Logout
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
});

export default NavBar;