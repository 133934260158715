import { db, auth } from "../firebase"; // Assuming you have these imports
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { Company } from "../models/Company";
import { CompanyUser } from "../models/CompanyUser";
import { getUserByID } from "./userQueries";

export const getCompanyUsers = async (companyID) => {
  const currentUser = auth.currentUser;
  const q = query(
    collection(db, "companyUsers"),
    where("uid", "==", currentUser.uid),
    where("companyID", "==", companyID)
  );

  const querySnapshot = await getDocs(q);
  const users = querySnapshot.forEach((doc) => {
    return doc.data();
  });
  return users;
};

export const getCompanies = async (ids) => {
  if (ids.length === 0) {
    return [];
  }
  const q = query(collection(db, "companies"), where("companyID", "in", ids));

  const querySnapshot = await getDocs(q);
  const companies = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return new Company({
      name: data.companyName,
      id: data.companyID,
      address: data.companyAddress,
      phone: data.companyPhone,
      roles: data.roles,
      chatRooms: data.chatRooms,
    });
  });
  return companies;
};

export const getCompanyByName = async (name) => {
  if (name.length === 0) {
    return;
  }
  const q = query(
    collection(db, "companies"),
    where("companyName", "==", name)
  );
  const querySnapshot = await getDocs(q);
  const id = querySnapshot.docs.map((doc) => doc.data().companyID)[0];
  return id;
};

export const getCurrentCompanyUser = async () => {
  const currentUser = auth.currentUser;
  const q = query(
    collection(db, "companyUsers"),
    where("uid", "==", currentUser.uid)
  );
  try {
    const querySnapshot = await getDocs(q);
    const companyUsers = querySnapshot.docs.map((doc) => {
      return new CompanyUser(doc.data()); // Cast the document data to CompanyUser
    });
    return companyUsers;
  } catch (error) {
    console.error("Error getting current company user: ");
  }
};

export const getCompanyUsersInCompanyExceptChosen = async (
  userID,
  companyID
) => {
  const q = query(
    collection(db, "companyUsers"),
    where("companyID", "==", companyID)
  );
  try {
    const querySnapshot = await getDocs(q);
    const companyUsers = await Promise.all(
      await querySnapshot.docs.map((doc) => doc.data())
    );

    const newCompanyUsers = companyUsers.filter(
      (companyUser) => companyUser.uid !== userID
    );
    return await Promise.all(newCompanyUsers);
  } catch (error) {
    throw Error(error);
  }
};

export const getAllCompanyUsersInCompany = async (companyID) => {
  try {
    const q = query(
      collection(db, "companyUsers"),
      where("companyID", "==", companyID)
    );

    const querySnapshot = await getDocs(q);
    const companyUsers = Promise.all(
      querySnapshot.docs.map((doc) => doc.data())
    );
    return companyUsers;
  } catch (error) {
    console.error("Error getting all company users in company: ");
  }
};

export const getCompanyUserByIDs = async (uid, companyID) => {
  const q = query(
    collection(db, "companyUsers"),
    where("companyID", "==", companyID),
    where("uid", "==", uid)
  );
  const querySnapshot = await getDocs(q);
  const companyUser = querySnapshot.docs.map((doc) => doc.data());
  return companyUser[0];
};

export const getCompanyUsersForCompany = async (companyID) => {
  const q = query(
    collection(db, "companyUsers"),
    where("companyID", "==", companyID)
  );
  const querySnapshot = await getDocs(q);

  const usersPromises = querySnapshot.docs.map(async (doc) => {
    const data = doc.data();
    const user = await getUserByID(data.uid);
    return new CompanyUser({
      id: data.uid,
      email: data.email,
      companyID: companyID,
      role: data.role,
      isSuspended: data.isSuspended,
      user: user,
    });
  });

  const users = await Promise.all(usersPromises);
  return users;
};

export const getCompanyUsersForCompanyExceptCurrent = async (companyID) => {
  const q = query(
    collection(db, "companyUsers"),
    where("companyID", "==", companyID)
  );
  const currentUser = auth.currentUser;
  const querySnapshot = await getDocs(q);

  const usersPromises = querySnapshot.docs.map(async (doc) => {
    const data = doc.data();
    const user = await getUserByID(data.uid);
    return new CompanyUser({
      id: data.uid,
      email: data.email,
      companyID: companyID,
      role: data.role,
      isSuspended: data.isSuspended,
      user: user,
    });
  });

  let users = await Promise.all(usersPromises);
  users = users.filter((user) => user.id !== currentUser.uid);
  return users;
};

export const createNewCompany = async (companyData) => {
  try {
    await addDoc(collection(db, "companies"), companyData);
    await addDoc(collection(db, "companyUsers"), {
      email: auth.currentUser.email,
      uid: auth.currentUser.uid,
      companyID: companyData.companyID,
      role: "Master",
    });
    const q = query(
      collection(db, "users"),
      where("uid", "==", auth.currentUser.uid)
    );
    const querySnapshot = await getDocs(q);
    const user = querySnapshot.docs[0];
    if (user) {
      // Initialize companies as an empty array if it doesn't exist
      let companies = user.data().companies || [];
      if (Array.isArray(companies)) {
        if (!companies.includes(companyData.companyID)) {
          companies.push(companyData.companyID);
          await updateDoc(user.ref, { companies: companies });
        }
      }
    }

    return companyData.companyID;
  } catch (error) {
    console.error("Error creating new company: ");
    throw new Error("Could not create new company");
  }
};

export const createNewAnnouncement = async (announcement) => {
  try {
    await addDoc(collection(db, "companyAnnouncements"), announcement);

    return announcement.companyID;
  } catch (error) {
    console.error("Error creating new announcement: ");
  }
};

export const getCompanyAnnouncements = async (companyID) => {
  const q = query(
    collection(db, "companyAnnouncements"),
    where("companyID", "==", companyID)
  );
  const querySnapshot = await getDocs(q);
  const announcements = querySnapshot.docs.map((doc) => doc.data());
  return announcements;
};

export const getUserByEmail = async (email) => {
  const q = query(
    collection(db, "users"),
    where("email", "==", email.toLowerCase())
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.docs.length === 1) {
    return querySnapshot.docs[0];
  }
  return null;
};

export const addChatrooms = async (userID_of_new_user, companyID) => {
  const companyQ = query(
    collection(db, "companies"),
    where("companyID", "==", companyID)
  );
  const companySnapshot = await Promise.resolve(getDocs(companyQ));
  let cmpny = companySnapshot.docs[0];

  if (cmpny.exists()) {
    let chatRooms = cmpny.data().chatRooms || [];
    const company_users = await Promise.all(
      await getAllCompanyUsersInCompany(companyID)
    );
    if (Array.isArray(chatRooms)) {
      company_users.forEach((company_user) => {
        chatRooms.push(`${companyID}${userID_of_new_user}${company_user.uid}`);
      });
      await Promise.resolve(
        await updateDoc(cmpny.ref, { chatRooms: chatRooms })
      );
    }
  }
};

export const inviteUserToCompany = async (email, companyID) => {
  try {
    const invitationCheckPromise = getDocs(
      query(
        collection(db, "companyUsers"),
        where("email", "==", email.toLowerCase()),
        where("companyID", "==", companyID)
      )
    );
    const userCheckPromise = getUserByEmail(email);

    const [invitationSnapshot, user] = await Promise.all([
      invitationCheckPromise,
      userCheckPromise,
    ]);

    // Check if the user is already invited to the company
    if (!invitationSnapshot.empty) {
      console.log("already invited!");
      return;
    }

    // Check if the user exists
    if (!user) {
      console.log("Error", "User not found.");
      return;
    }

    //add chatrooms
    await addChatrooms(user.data().uid, companyID);

    // Invite the user to the company
    await Promise.resolve(
      await addDoc(collection(db, "companyUsers"), {
        uid: user.data().uid,
        email: email.toLowerCase(),
        companyID: companyID,
        role: "User",
        isSuspended: false,
      })
    );

    if (user.exists()) {
      // Initialize companies as an empty array if it doesn't exist
      let companies = user.data().companies || [];
      if (Array.isArray(companies)) {
        if (!companies.includes(companyID)) {
          companies.push(companyID);
          await updateDoc(user.ref, { companies: companies });
        }
      }
    }

    // Provide feedback on successful invitation
    console.log("Success", "User successfully invited to the company.");
    return true;
  } catch (error) {
    console.error("Error inviting user to company: ");
    console.log("Error", "Failed to invite user to company.");
  }
};
