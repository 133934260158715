import React, { useState, useEffect, useContext } from "react";
import classes from "../../styling//chat_screen/ChatRoom.module.css";
import { auth, db } from "../../../firebase";
import {
  addDoc,
  collection,
  where,
  onSnapshot,
  query,
  serverTimestamp,
} from "firebase/firestore";
import {
  getTypingStatusOfOtherUserInChatroom,
  setChatroomNotifications,
  setTypingStatusOfCurrentUser,
  getInChat,
  getChatroomNotifications,
  setLastMessage,
  setChatroomState,
} from "../../../queries/chatQueries";
import ChatBubble from "./ChatBubble";
import { MobXProviderContext } from "mobx-react";

const ChatRoom = ({ userToChatWith }) => {
  const [message_input, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [textAreaRows] = useState(1);
  const [typing, setTyping] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const textAreaCols = 40;
  const { domainStore } = useContext(MobXProviderContext);

  // updates whenever a user is typing
  useEffect(() => {
    const company = domainStore.companyStore.getSelectedCompany();
    if (!company.id) return;
    if (company) {
      const q = query(
        collection(db, "companies"),
        where("companyID", "==", company.id)
      );

      const unsubscribe = onSnapshot(q, async (snapshot) => {
        if (userToChatWith) {
          const status = await Promise.resolve(
            getTypingStatusOfOtherUserInChatroom(
              company.id,
              userToChatWith.chatRoom
            )
          );

          setTyping(status);
        }
      });

      return () => unsubscribe();
    }
  }, [domainStore.companyStore.selectedCompany, userToChatWith]);

  // gets all the messages whenever a new one is added
  useEffect(() => {
    const company = domainStore.companyStore.getSelectedCompany();
    if (!company.id) return;
    if (userToChatWith) {
      // queries for the messages corresponding to the current chatroom
      const q = query(
        collection(db, "messages"),
        where("chatRoom", "==", userToChatWith.chatRoom)
      );

      // an event listener for the query
      const unsubscribe = onSnapshot(q, (snapshot) => {
        try {
          // loads up every message from the query that has non-null createdAt attribute
          let new_messages = [];
          snapshot.forEach((doc) => {
            const new_msg = doc.data();
            if (new_msg.createdAt) {
              new_messages.push(doc.data());
            }
          });
          // sorts the messages into descending order by the date at which they were created
          new_messages.sort((a, b) =>
            a.createdAt && b.createdAt
              ? b.createdAt.seconds - a.createdAt.seconds
              : 1
          );

          // creates a new list of message objects with only the information needed for each chat bubble
          let n = 0;
          const message_objects = new_messages.map((message) => {
            const sent =
              message.sentByID === auth.currentUser.uid ? true : false;
            const message_object = {
              text: message.text,
              sent: sent,
              key: n,
            };
            n++;
            return message_object;
          });

          setMessages(message_objects);
          setButtonDisabled(false);
        } catch (error) {
          throw Error(error);
        }
      });

      return () => unsubscribe();
    }
  }, [userToChatWith]);

  /*
  @brief sends the new message to the messages collection in firestore and clears the text input
  it also adds a notification to the user being sent to if they are not in the chat
  */
  const onSendHandler = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    const company = domainStore.companyStore.getSelectedCompany();
    if (message_input === "" || !userToChatWith || !company.id) return;
    const message = {
      createdAt: serverTimestamp(),
      sentByID: auth.currentUser.uid,
      chatRoom: userToChatWith.chatRoom,
      text: message_input,
    };

    setMessageInput("");
    await Promise.resolve(addDoc(collection(db, "messages"), message));
    const inChat = await Promise.resolve(
      getInChat(company.id, userToChatWith.chatRoom, userToChatWith.user.id)
    );

    if (!inChat) {
      const currentNotifications = await Promise.resolve(
        getChatroomNotifications(
          company.id,
          userToChatWith.chatRoom,
          userToChatWith.user.id
        )
      );

      await Promise.resolve(
        setChatroomState(
          company.id,
          userToChatWith.chatRoom,
          userToChatWith.user.id,
          message.text,
          currentNotifications + 1
        )
      );
      setButtonDisabled(false);
      return;
    }
    await setLastMessage(company.id, userToChatWith.chatRoom, message.text);
    setButtonDisabled(false);
  };
  return (
    <div className={classes.outerContainer}>
      <header className={classes.header}>
        <h1>
          {!userToChatWith
            ? "No Chat Selected!"
            : `${userToChatWith.user.firstName} ${userToChatWith.user.lastName}`}
        </h1>
      </header>
      <ul className={classes.chat} id="scrollableChat">
        {typing ? (
          <li
            key={messages.length}
            className={classes.typingStatusOuterContainer}
          >
            <div className={classes.typingStatusBubble}>
              <p className={classes.typingStatusText}>Typing...</p>
            </div>
          </li>
        ) : (
          <></>
        )}
        {messages.map((message) => (
          <ChatBubble key={message.key} messageObject={message} />
        ))}
        <li className={classes.chatStartContainer}>
          <p>This is the start of the chat!</p>
        </li>
      </ul>
      <div className={classes.inputOuterContainer}>
        <form onSubmit={onSendHandler} className={classes.inputInnerContainer}>
          <textarea
            name={"sendMessageText"}
            className={classes.textInput}
            value={message_input}
            onChange={(e) => setMessageInput(e.target.value)}
            onFocus={async () => {
              const company = domainStore.companyStore.getSelectedCompany();
              if (userToChatWith && company.id) {
                await setTypingStatusOfCurrentUser(
                  company.id,
                  userToChatWith.chatRoom,
                  true
                );
              }
            }}
            onBlur={async () => {
              const company = domainStore.companyStore.getSelectedCompany();
              if (userToChatWith && company.id) {
                await setTypingStatusOfCurrentUser(
                  company.id,
                  userToChatWith.chatRoom,
                  false
                );
              }
            }}
            rows={textAreaRows}
            cols={textAreaCols}
            placeholder="Type a message"
            maxLength={250}
          />
          <button type="submit" className={classes.sendButton}>
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatRoom;
