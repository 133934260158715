import { makeAutoObservable } from 'mobx';
import { User } from '../models/User';

export default class UserStore {
    currentUser = new User();
    updateFlag = 0;

    constructor() {
        makeAutoObservable(this);
    }

    rehydrate(data) {
        if (data) {
        this.setCurrentUser(data.currentUser);
        }
    }

    toJSON() {
        return {
        currentUser: this.currentUser,
        };
    }

    setCurrentUser(user) {
        this.currentUser = new User(user);
    }


    getCurrentUser() {
        return this.currentUser;
    }

    setFirstName(firstName) {
        this.currentUser.firstName = firstName;
    }

    getFirstName() {
        return this.currentUser.firstName;
    }

    setLastName(lastName) {
        this.currentUser.lastName = lastName;
    }

    getLastName() {
        return this.currentUser.lastName;
    }

    setEmail(email) {
        this.currentUser.email = email;
    }

    getEmail() {
        return this.currentUser.email;
    }

    setContactPhone(contactPhone) {
        this.currentUser.contactPhone = contactPhone;
    }

    getContactPhone() {
        return this.currentUser.contactPhone;
    }

    setCompanies(companies) {
        this.currentUser.companies = companies;
    }

    getCompanies() {
        return this.currentUser.companies;
    }

    setDefaultCompany(defaultCompany) {
        this.currentUser.defaultCompany = defaultCompany;
    }

    getDefaultCompany(){
        return this.currentUser.defaultCompany;
    }

    setProfilePicture(profilePicture) {
        this.currentUser.profilePicture = profilePicture;
    }

    getProfilePicture() {
        return this.currentUser.profilePicture;
    }

    setCurrentUserProfile(user) {
        this.currentUser.firstName = user.firstName;
        this.currentUser.lastName = user.lastName;
        this.currentUser.email = user.email;
        this.currentUser.contactPhone = user.contactPhone;
    }

    clearStore() {
        this.currentUser = new User();
    }
}