import React, { useEffect, useState, useContext } from "react";
import classes from "../styling/UserList.module.css";
import UserInfoDisplay from "../../components/code/UserInfoDisplay";
import { getCompanyUsersForCompanyExceptCurrent } from "../../queries/companyQueries";
import { MobXProviderContext } from "mobx-react";

const UserList = () => {
  const [userInfos, setUserInfos] = useState([]);
  const [filteredUserInfos, setFilteredUserInfos] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [roles] = useState(["Manager", "User", "Master"]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { domainStore } = useContext(MobXProviderContext);
  const [searchText, setSearchText] = useState("");

  const fetchUserInfos = async (companyID) => {
    try {
      const users = await getCompanyUsersForCompanyExceptCurrent(companyID);
      setUserInfos(users);
    } catch (error) {
      setError("Failed to load user infos");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const company = domainStore.companyStore.getSelectedCompany();
      if (!company.id) {
        setLoading(false);
        return;
      }
      await fetchUserInfos(company.id);
      setLoading(false);
    };

    fetchData();
  }, [domainStore.companyStore.selectedCompany]);

  useEffect(() => {
    let newUserInfos = [...userInfos];

    newUserInfos =
      searchText !== ""
        ? newUserInfos.filter((userInfo) => {
            let name = `${userInfo.user.firstName} ${userInfo.user.lastName}`;
            name = name.toLowerCase();
            if (selectedRole === "") {
              return (
                name.includes(searchText.toLowerCase()) ||
                userInfo.email.includes(searchText.toLowerCase())
              );
            }
            return (
              (name.includes(searchText.toLowerCase()) ||
                userInfo.email.includes(searchText.toLowerCase())) &&
              userInfo.role === selectedRole
            );
          })
        : newUserInfos.filter((userInfo) => {
            if (selectedRole === "") {
              return true;
            }
            return userInfo.role === selectedRole;
          });

    setFilteredUserInfos(newUserInfos);
  }, [userInfos, searchText, selectedRole]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className={classes.outsideContainer}>
      <div className={classes.filterContainer}>
        {domainStore.companyStore.getSelectedCompany().id ? (
          <>
            <input
              type="text"
              value={searchText}
              placeholder="Search..."
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              className={classes.searchInput}
            />
            <select
              name="Roles"
              value={selectedRole || ""}
              onChange={(e) => {
                setSelectedRole(e.target.value);
              }}
              className={classes.rolesDropdown}
            >
              <option value="" key="" className={classes.dropdownOption}>
                No role selected
              </option>
              {roles &&
                roles.map((role) => (
                  <option
                    key={role}
                    value={role}
                    className={classes.dropdownOption}
                  >
                    {role}
                  </option>
                ))}
            </select>
          </>
        ) : (
          <></>
        )}
      </div>
      <ul className={classes.gridContainer}>
        {userInfos.length > 0 ? (
          filteredUserInfos.map((user, index) => (
            <UserInfoDisplay user={user} key={index} />
          ))
        ) : (
          <p>No users found! Make sure a company is selected!</p>
        )}
      </ul>
    </div>
  );
};

export default UserList;
