import { makeAutoObservable, autorun } from 'mobx';
import UserStore from './userStore';
import CompanyStore from './companyStore';
import { encryptData, decryptData } from '../cryptoUtils';

export default class DomainStore {
  userStore;
  companyStore;

  constructor() {
    this.userStore = new UserStore();
    this.companyStore = new CompanyStore();

    makeAutoObservable(this);

    this.rehydrate();

    autorun(() => {
      if (this.userStore.currentUser.id !== '') {
        const encryptedData = encryptData(this.toJSON());
        localStorage.setItem('domainStore', encryptedData);
      }
    });
  }

  rehydrate() {
    const encryptedData = localStorage.getItem('domainStore');
    if (encryptedData) {
      const decryptedData = decryptData(encryptedData);
      if (decryptedData) {
        this.userStore.rehydrate(decryptedData.userStore);
        this.companyStore.rehydrate(decryptedData.companyStore);
      }
    }
  }

  toJSON() {
    return {
      userStore: this.userStore.toJSON(),
      companyStore: this.companyStore.toJSON(),
    };
  }

  clearStores() {
    this.userStore.clearStore();
    this.companyStore.clearStore();
    localStorage.removeItem('domainStore');
  }
}