import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Paper,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
}));

const ReadingPage = ({ chapter, onBack, onNextChapter }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h1">
          {chapter.title}
        </Typography>
        {isMobile && (
          <IconButton onClick={toggleDrawer} edge="end" aria-label="menu">
            <MenuIcon />
          </IconButton>
        )}
      </Box>
      <Divider sx={{ mb: 4 }} />
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: 1 }}>
          <StyledPaper elevation={3}>
            <Typography
              component="div"
              dangerouslySetInnerHTML={{ __html: chapter.content }}
            />
          </StyledPaper>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            <Button variant="outlined" onClick={onBack} startIcon={<ChevronLeftIcon />}>
              Back to Course
            </Button>
            <Button variant="contained" onClick={onNextChapter} endIcon={<ChevronRightIcon />}>
              Next Chapter
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ReadingPage;