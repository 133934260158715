import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth, applyActionCode } from 'firebase/auth';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState('Initializing...');
  const [debugInfo, setDebugInfo] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const auth = getAuth();
      const params = new URLSearchParams(location.search);
      const actionCode = params.get('oobCode');
      let userId = params.get('userId');

      // If userId is not directly in the URL, check if it's in the continueUrl
      if (!userId) {
        const continueUrl = params.get('continueUrl');
        if (continueUrl) {
          const continueUrlParams = new URLSearchParams(new URL(continueUrl).search);
          userId = continueUrlParams.get('userId');
        }
      }

      setDebugInfo({ actionCode, userId, fullUrl: location.search });

      if (!actionCode && !userId) {
        setVerificationStatus('Please check your email to verify account.');
        setTimeout(() => navigate('/login'), 3000);
        return;
      }

      try {
        if (actionCode) {
          await applyActionCode(auth, actionCode);
          setVerificationStatus('Email verified successfully!');
        }

        if (userId) {
          // Move user data from tempUsers to users collection
          const tempUserDoc = await getDoc(doc(db, 'tempUsers', userId));
          if (tempUserDoc.exists()) {
            const userData = tempUserDoc.data();
            await setDoc(doc(db, 'users', userId), userData);
            await deleteDoc(doc(db, 'tempUsers', userId));
            setVerificationStatus('Email verified and user data moved successfully!');
          } else {
            setVerificationStatus('User data not found. Please contact support.');
          }
        }

        setTimeout(() => navigate('/login'), 3000);
      } catch (error) {
        console.error('Verification error:', error);
        setVerificationStatus(`Verification failed: ${error.message}`);
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <div>
      <h2>Email Verification</h2>
      <p>{verificationStatus}</p>
      <details>
        <summary>Debug Information</summary>
        <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
      </details>
    </div>
  );
};

export default VerifyEmail;