import React from 'react';
import '../styling/BannerUploadAndDisplay.css';
import { Box, Button } from '@mui/material';

const BannerUploadAndDisplay = ({ onImageSelect, currentImageFile }) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            onImageSelect(file);
        }
    };

    return (
        <Box>
            <input
                accept="image/*"
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="banner-upload"
            />
            <label htmlFor="banner-upload">
                <Button variant="contained" component="span">
                    Select Banner Image
                </Button>
            </label>
            {currentImageFile && (
                <Box mt={2}>
                    <img
                        src={URL.createObjectURL(currentImageFile)}
                        alt="Course banner preview"
                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default BannerUploadAndDisplay;