import { makeAutoObservable } from 'mobx';

export class Company {
  name = '';
  id = '';
  address = '';
  phone = '';
  roles = [];
  chatRooms = [];
  isSuspended = false;

  constructor(data = {}) {
    this.name = data.name || '';
    this.id = data.id || '';
    this.address = data.address || '';
    this.phone = data.phone || '';
    this.roles = data.roles || [];
    this.chatRooms = data.chatRooms || [];
    this.isSuspended = data.isSuspended || false;
    // this.companyUsers = data.companyUsers || [];
    makeAutoObservable(this);
  }
}
