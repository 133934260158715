import React, { useContext, useState } from "react";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import classes from "../styling/LoginScreen.module.css";
import { getCurrentCompanyUser, getCompanies } from "../../queries/companyQueries";
import { getUserByID } from "../../queries/userQueries";
import { useNavigate } from 'react-router-dom';
import { MobXProviderContext } from "mobx-react";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { domainStore } = useContext(MobXProviderContext);
  const navigate = useNavigate(); 

  const loadData = async () => {
    try {
      const [companyUser, user] = await Promise.all([
        getCurrentCompanyUser(),
        getUserByID(auth.currentUser.uid)
      ]);
      const ids = companyUser.map((cu) => cu.companyID);
      const companies = await getCompanies(ids);
      const defaultCompany = companies.find(company => company.id === user.defaultCompany);

      domainStore.userStore.setCurrentUser(user);
      domainStore.userStore.setCompanies(companies);
      if (defaultCompany) {
        domainStore.companyStore.setSelectedCompany(defaultCompany);
      }
    } catch (error) {
      console.error("Failed to load data:");
      setError("Failed to load data. Please try again.");
    }
  };

  const storeTokenWithExpiration = (token, expirationPeriod) => {
    localStorage.setItem('token', token);
    const expirationTime = new Date().getTime() + expirationPeriod;
    localStorage.setItem('token_expiration', expirationTime.toString());
  };

  const handleSignUp = async () => {
    navigate("/signup");
  };

  const handleLogin = async () => {
    try {
      const userCredentials = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredentials.user;

      if (!user.emailVerified) {
        setError("Your email is not verified. Please check your inbox and verify your email.");
        return;
      }

      const token = await user.getIdToken();
      await loadData();
      storeTokenWithExpiration(token, 3600000);
      navigate("/");
    } catch (error) {
      console.error("Error during login:", error);
      setError(error.message);
      domainStore.clearStores();
      localStorage.removeItem("domainStore");
    }
  };

  return (
    <div className={classes.background}>
      <div className={classes.formContainer}>
        <h1 className={classes.title}>Login</h1>
        {error && <p className={classes.error}>{error}</p>}
        <div className={classes.inputContainer}>
          <input
            type="text"
            placeholder="Email"
            className={classes.input}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className={classes.input}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div className={classes.buttonContainer}>
          <button onClick={handleLogin} className={classes.button}>
            <p className={classes.buttonText}>Login</p>
          </button>
          <button
            onClick={handleSignUp}
            className={`${classes.button} ${classes.buttonOutline}`}
          >
            <p className={classes.buttonOutlineText}>Register</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;