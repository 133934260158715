import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const CircularProgressBar = ({ value }) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      {/* Background circle */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={120}
        thickness={5}
        sx={{ color: (theme) => theme.palette.grey[200] }}
      />
      {/* Progress circle */}
      <CircularProgress
        variant="determinate"
        value={value}
        size={120}
        thickness={5}
        sx={{
          color: (theme) => theme.palette.primary.main,
          position: 'absolute',
          left: 0,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" component="div" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressBar;
