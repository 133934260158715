// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMv1rT75ZmwJQG6RoWnD3sz41VGGXWe5Y",
  authDomain: "datasaver-8721b.firebaseapp.com",
  projectId: "datasaver-8721b",
  storageBucket: "datasaver-8721b.appspot.com",
  messagingSenderId: "24576517683",
  appId: "1:24576517683:web:f8d843367c0791c13fe546",
};


const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth();
